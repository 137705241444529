import React, { Component } from 'react';
import { IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import { NormalText, SmallText, Spacer, StrongText, Title } from '../common';
import { makeKey, isDefined, forwardTo, isTaxActiveForOrderType } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import { releaseVoucher, removeVoucher, setModal } from '../../store/actions';
import Modal from '../../components/modal';
import './index.css';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import applyVouchers from '../../screens/applyVouchers';
import { getConfig } from '../../appConfig';

class OrderContent extends Component {
  drawSubItems = (basketItem = {} /*, basketItemIndex*/) => {
    const { selectedChoices, quantity } = basketItem;
    const basketInstance = this.props.basketInstance || Basket;
    const { profile } = this.props;

    if (selectedChoices && selectedChoices.length > 0) {
      return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
        return (choiceGroup || []).map((choice, choiceIndex) => {
          const { sku } = choice;
          return (
            <div>
              <IonRow
                className=""
                key={makeKey(
                  choiceGroupIndex,
                  choiceIndex,
                  sku,
                )} /*onClick={ () => this.handleOrderItemClick(basketItem, basketItemIndex) }*/
              >
                <IonCol size="50px"></IonCol>
                <IonCol className="paddLR grow">
                  {quantity} x {Basket.getProductName(choice, profile)}
                </IonCol>
                <IonCol className="righted paddLR">
                  {basketInstance.calculateSubItemPrice(choice, quantity) !== 0
                    ? basketInstance._calculateSubItemPrice(choice, quantity)
                    : null}
                </IonCol>
              </IonRow>
              {choice.subItems && this.drawSubItemChoices(choice.subItems)}
            </div>
          );
        });
      });
    }
    return null;
  };

  drawSubItemChoices = (subItemChoices) => {
    const basketInstance = this.props.basketInstance || Basket;
    const { profile } = this.props;
    return (subItemChoices || []).map((choice, choiceIndex) => {
      const { sku } = choice;
      return (
        <div>
          <IonRow className="bordered-bottom" key={makeKey(choiceIndex, sku)}>
            <IonCol size="250px"></IonCol>
            <IonCol className="paddLR grow">
              {'1'} x {Basket.getProductName(choice, profile)}
            </IonCol>
            <IonCol className="righted paddLR">
              {basketInstance.calculateSubItemPrice(choice) !== 0
                ? basketInstance._calculateSubItemPrice(choice)
                : null}
            </IonCol>
          </IonRow>
        </div>
      );
    });
  };

  render() {
    const {
      handleOrderItemClick,
      __,
      history,
      profile,
      showAddItems,
      dispatch,
      isRemoveVoucherModalOpen,
      isCollection,
    } = this.props;
    const basketInstance = this.props.basketInstance || Basket;
    const type = this.props.type || 'order';
    const appliedPoints = basketInstance.getAppliedPoints();
    const deliveryPrice = basketInstance.getDeliveryPrice();
    const deliveryPriceUnformated = basketInstance._getDeliveryPrice();
    const appliedVoucher = basketInstance.getAppliedVocuher();
    const { deliveryFeeVatRate } = getConfig()?.taxes || {};
    let location =
      history && history.location && history.location.pathname === '/history-details'
        ? true
        : false;
    const isCorporate = profile.is_corporate;
    let subtotal = basketInstance._getSubTotal();
    if (isCorporate) {
      subtotal = basketInstance.round(
        basketInstance.getSubTotalWithoutTax() + basketInstance._getDeliveryPriceExcludeVat(),
        2,
      );
      subtotal = basketInstance.formatPrice(subtotal);
    }
    return (
      <>
        {showAddItems ? (
          <IonRow className="righted add-items-row">
            <IonButton
              size="small"
              color="primary"
              className="rounded add-items-btn"
              onClick={() => forwardTo('/order')}
            >
              + {__('Add Items')}
            </IonButton>
          </IonRow>
        ) : null}
        <IonGrid className="box-wrapper box-list order-content-wrapper paddL">
          {basketInstance.getItems().map((basketItem, basketItemIndex) => {
            const { quantity, item, instructions } = basketItem;
            return (
              <div key={basketItemIndex} className="basket-item-wrapper">
                <IonRow
                  key={makeKey(basketItemIndex, item.sku)}
                  onClick={() => {
                    if (handleOrderItemClick) {
                      handleOrderItemClick(basketItem, basketItemIndex);
                    }
                  }}
                >
                  <IonCol className="paddLR grow text-start">
                    <NormalText>
                      {quantity} x {Basket.getProductName(item, profile)}
                    </NormalText>
                  </IonCol>
                  <IonCol className="righted paddLR text-start">
                    <NormalText>
                      {basketInstance.calculateItemPriceByIndexWithoutTax(basketItemIndex, false) >
                      0
                        ? basketInstance._calculateItemPriceByIndexWithoutTax(
                            basketItemIndex,
                            false,
                          )
                        : null}
                    </NormalText>
                  </IonCol>
                </IonRow>

                {this.drawSubItems(basketItem, basketItemIndex)}
                {/* {instructions && instructions !== '' ? (
                <IonRow>
                  <IonCol>
                    <SmallText>{__('Special Notes')}:</SmallText>
                  </IonCol>
                  <IonCol className="righted instruction-grow">
                    <SmallText>{instructions}</SmallText>
                  </IonCol>
                </IonRow>
              ) : null} */}
              </div>
            );
          })}
          {isCorporate && deliveryPriceUnformated > 0 ? (
            <IonRow className="">
              <IonCol className="paddLR grow text-start">{__('Delivery Charge')}</IonCol>
              <IonCol className="righted paddLR">
                {isCorporate || basketInstance.getItemsTax() > 0
                  ? basketInstance.formatPrice(
                      deliveryPriceUnformated / (1 + deliveryFeeVatRate / 100),
                    )
                  : deliveryPrice}
              </IonCol>
            </IonRow>
          ) : null}
          {isDefined(appliedPoints) && appliedPoints > 0 ? (
            <IonRow
              className="total-"
              onClick={() => {
                if (type === 'order' && history) {
                  history.push('/apply-points');
                }
              }}
            >
              <IonCol className="paddLR grow">
                {appliedPoints}&nbsp;{__('Points Redeem')}
              </IonCol>
              <IonCol className="righted paddLR">
                {basketInstance._calculatePointsAppliedPrice(null, true)}
              </IonCol>
            </IonRow>
          ) : null}
          {appliedVoucher &&
            appliedVoucher.length > 0 &&
            appliedVoucher.map((voucher) => {
              let voucherName = voucher.name;
              if (voucher && voucher.reward && voucher.reward.name) {
                voucherName = voucher.reward.name;
              }
              return (
                <div key={voucher.id}>
                  <Spacer size={1} />
                  <Title className="centered">{__('Vouchers')}</Title>
                  <IonRow
                    className=""
                    onClick={() =>
                      location ? null : dispatch(setModal('isRemoveVoucherModalOpen', true))
                    }
                  >
                    <IonCol className="paddLR grow ">
                      <NormalText>{__(voucherName)}</NormalText>{' '}
                    </IonCol>
                    <IonCol className="righted paddLR">
                      <NormalText>
                        {basketInstance._calculateAppliedVocuhersPrice(true, null)}
                      </NormalText>
                    </IonCol>
                  </IonRow>
                  <Modal
                    className="remove-apply-voucuher"
                    title={__('Do you want to remove voucher') + ' ?'}
                    actionLabel={__('Yes')}
                    action={() => dispatch(releaseVoucher({discount_id:voucher.voucher_code,clearVouchers:true}))}
                    isOpen={!!isRemoveVoucherModalOpen}
                    onDidDismiss={() => dispatch(setModal('isRemoveVoucherModalOpen', false))}
                    noContent={true}
                  />
                </div>
              );
            })
          }
          
          {(appliedVoucher.length ||
            basketInstance.discount ||
            basketInstance.getItemsTax() > 0) && (
            <IonRow className={appliedVoucher.length > 0 ? '' : 'subtotal-'}>
              <IonCol className="paddLR grow">
                <StrongText className="uppercase">{__('Subtotal')}</StrongText>
              </IonCol>
              <IonCol className="righted paddLR">
                <StrongText>{subtotal}</StrongText>
              </IonCol>
            </IonRow>
          )}
          {basketInstance.getItemsTax() > 0 ? (
            <IonRow className={appliedVoucher.length > 0 ? '' : 'subtotal-'}>
              <IonCol className="paddLR grow">
                <NormalText>{__('VAT')}</NormalText>
              </IonCol>
              <IonCol className="righted paddLR">
                <NormalText>{basketInstance.formatPrice(basketInstance.getItemsTax())}</NormalText>
              </IonCol>
            </IonRow>
          ) : null}
          {basketInstance.discount && isCollection && (
            <IonRow className="">
              <IonCol className="paddLR grow">
                <NormalText>{basketInstance.discount.title}</NormalText>
              </IonCol>
              <IonCol className="righted paddLR">
                <NormalText>
                  -{basketInstance.formatPrice(basketInstance.calculateMembershipDiscount())}
                </NormalText>
              </IonCol>
            </IonRow>
          )}
          {!isCorporate && deliveryPriceUnformated > 0 ? (
            <IonRow className="">
              <IonCol className="paddLR grow text-start">{__('Delivery Charge')}</IonCol>
              <IonCol className="righted paddLR">
                {/* basketInstance._calculatePointsAppliedPrice(null, true) */} {deliveryPrice}
              </IonCol>
            </IonRow>
          ) : null}
          {isCorporate || basketInstance.getItemsTax() > 0 ? (
            <IonRow>
              <IonCol className="paddLR text-start">
                <StrongText className="uppercase paddLR ">{__('Total')}</StrongText>
              </IonCol>
              <IonCol className="righted paddLR">
                <StrongText>
                  {basketInstance.formatPrice(basketInstance.calculateTotalWithoutDiscounts())}
                </StrongText>
              </IonCol>
            </IonRow>
          ) : null}
          {isDefined(appliedPoints) &&
          appliedPoints > 0 &&
          (isCorporate || basketInstance.getItemsTax() > 0) ? (
            <IonRow>
              <IonCol className="paddLR text-start">
                <StrongText className="uppercase paddLR ">{__('Amount due')}</StrongText>
              </IonCol>
              <IonCol className="righted paddLR">
                <StrongText>{basketInstance._getTotal(true)}</StrongText>
              </IonCol>
            </IonRow>
          ) : null}
          {!isCorporate && basketInstance.getItemsTax() == 0 ? (
            <IonRow>
              <IonCol className="paddLR text-start">
                <StrongText className="uppercase paddLR ">{__('Total')}</StrongText>
              </IonCol>
              <IonCol className="righted paddLR">
                <StrongText>{basketInstance._getTotal(true)}</StrongText>
              </IonCol>
            </IonRow>
          ) : null}
        </IonGrid>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    isRemoveVoucherModalOpen: store.profile.isRemoveVoucherModalOpen || false,
    isCollection:
      store.orders.deliveryOption !== null && store.orders.deliveryOption.id === 'collection',
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderContent)));
