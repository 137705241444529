import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { IonButton, IonItem, IonIcon, IonLabel, IonInput, IonAlert } from '@ionic/react';
import { chevronForwardOutline, pencilOutline } from 'ionicons/icons';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import ValidateButton from '../../components/validateButton';
import Modal from '../../components/modal';
import Incrementer from '../../components/incrementer';
import { PillGroup } from '../../components/pill';
import {
  Title,
  SmallText,
  Sectiontitle,
  Spacer,
  FieldError,
  NormalText,
  StrongText,
  Subtitle,
} from '../../components/common';
import OrderContent from '../../components/orderContent';
import { ApplyPoints } from '../../screens/applyPoints';
import {
  forwardTo,
  validateProfileData,
  goBack,
  go,
  isEmptyObject,
  isWebConfig,
  deepIsDefined,
  isDefined,
} from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig';
import moment from '../../lib/moment';
import {
  setModal,
  setProtectedReferrer,
  restoreAuth,
  setOrderTypeMembership,
  setOrderTypeRegular,
  setOrderSubscriptionFlow,
  setSelectedSubscription,
  getSubscriptions,
  getIkentooMenu,
  showToast,
  loading,
} from '../../store/actions';
import './index.css';
import { Vouchers } from '../vouchers';
import 'moment-timezone';
import Mobiscroll from '../../components/mobiscroll';

const { SelectOption } = Mobiscroll;
const loyaltyIcon = require('../../assets/images/icons/wallet.svg');

const {
  getRestauranName,
  getOrderDate,
  getOrderTime,
  changeItemQuantity,
  itemsCount,
  getItems,
  setMobile,
  getMobile,
  getPoNumber,
  setPoNumber,
  getAllergen,
  getTotal,
  isMinimumOrderTotalSatisfied,
} = Basket;

class OrderSummaryRaw extends React.Component {
  constructor(props) {
    super(props);
    this.props.getSubscriptions();
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    if (!getPoNumber() && this.props.profile && this.props.profile.po_number) {
      setPoNumber(this.props.profile.po_number);
    }
    this.state = {
      quantityModal: null,
      allergenModalOpen: false,
      applyPointsModalOpen: false,
      redeemVoucherModalOpen: false,
      mobile: getMobile() || '',
      po_number: getPoNumber() || '',
      voucherCode: '',
      formErrors: {
        voucherCode: '',
      },
      itemIsDisabled: false,
      showPoNumberAlert: false,
      voucherFieldVisible: false,
      mobile_code: this.props.profile.mobile_code ? this.props.profile.mobile_code : null,
      mobile_value: this.props.profile.mobile_value
        ? this.props.profile.mobile_value
        : this.props.profile.mobile == this.props.profile.mobile_code
          ? ''
          : this.props.profile.mobile,
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    const { selectedSubscription, subscriptions, profile } = this.props;

    if (selectedSubscription?.idx >= 0 && profile.active_subscription.id) {
      const active_subscription = subscriptions[selectedSubscription?.idx];
      if (active_subscription && active_subscription.id && this.props.isCollection) {
        Basket.setDiscount(active_subscription);
      }
    } else if (profile.active_subscription && profile.active_subscription.id) {
      const index = subscriptions.findIndex((e) => e.id === profile.active_subscription.id);
      this.props.setSelectedSubscription({ id: profile.active_subscription.id, idx: index });
      Basket.setDiscount(profile.active_subscription);
    } else {
      Basket.setDiscount(0);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({ mobile: this.props.profile.mobile });
    }
    if (this.props.profile.po_number !== prevProps.profile.po_number) {
      this.setState({ po_number: this.props.profile.po_number });
    }
  }

  handleInput = (key, val) => {
    this.setState({ [key]: val });
    if (key === 'mobile' || key == 'mobile_code' || key == 'mobile_value') {
      if (key == 'mobile') {
        setMobile(val);
      }
    } else {
      setPoNumber(val);
    }
  };

  isItemInMenu = (item, selectedMenu) => {
    let inMenu = false;
    if (selectedMenu?.menuEntryGroups) {
      selectedMenu.menuEntryGroups.forEach((group) => {
        group.menuEntry.forEach((menuItem) => {
          if (menuItem.sku === item.item.sku) {
            inMenu = true;
          }
        });
      });
      return inMenu;
    } else {
      return false;
    }
  };
  isProductEnabled = (item, selectedRestaurant) => {
    let format_date = 'YYYY-MM-DD HH:mm:ss';
    const isValid =
      item &&
      isDefined(item.sku) &&
      selectedRestaurant &&
      [
        ...(selectedRestaurant.disabled_skus || []),
        ...(selectedRestaurant.snoozed_skus || []),
      ].indexOf(item.sku.includes('###') ? item.sku.replace('###', '') : item.sku);

    return isValid !== null && isValid !== -1
      ? selectedRestaurant &&
        Basket._getCollectionTime() &&
        selectedRestaurant.snoozeEnd_time.length - 1 >= isValid
        ? moment(moment(selectedRestaurant.snoozeEnd_time[isValid]).format(format_date)).isBefore(
          moment(Basket._collection_time).format(format_date),
        )
          ? true
          : false
        : false
      : true;
  };
  handleSubmit = (__) => {
    const { ikentooMenusForLocation, ikentooMenu, dispatch } = this.props;
    const selectedRestaurant = this.props.restaurants.find(
      (restaurant) => restaurant.id == Basket.getRestaurant().id,
    );
    const selectedMenuId = Basket.getMenu();
    dispatch(getIkentooMenu(selectedMenuId, selectedRestaurant.business_location_id, false));
    const items = Basket.getItems();
    let selectedMenu = {};
    if (ikentooMenusForLocation.length !== 0) {
      selectedMenu = ikentooMenusForLocation.find((menu) => menu.menu_id === selectedMenuId);
    } else if (ikentooMenu && selectedMenuId === ikentooMenu?.menu_id) {
      selectedMenu = ikentooMenu;
    }
    let unavailableItems = [];
    for (let i = 0; i < items.length; i++) {
      const isInMenu = this.isItemInMenu(items[i], selectedMenu);
      const isActive = this.isProductEnabled(items[i].item, selectedRestaurant);
      if (!isActive || !isInMenu) {
        unavailableItems.push(i);
        Basket.removeFromBasket(i);
      }
    }
    if (unavailableItems.length > 0) {
      this.props.dispatch(
        showToast(
          __('Some items are no longer available and have been removed from your order'),
          'warning',
        ),
      );
    }
    this.props.setOrderTypeRegular();
    const { cards } = this.props;

    if (!isMinimumOrderTotalSatisfied()) {
      // display toast with flag 'true'
      isMinimumOrderTotalSatisfied(true);
      return;
    }

    if (unavailableItems.length === 0) {
      if (getTotal() === 0) {
        this.props.dispatch(loading(true));
        Basket.createOrder();
        return;
      }
      if (cards && cards.length >= 1) {
        if (this.props.profile.mobile && this.props.profile.first_name) {
          if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.fromHistory
          )
            forwardTo('/checkout', { fromHistory: true });
          else forwardTo('/checkout');
          // setMobile(this.props.profile.mobile);
        } else {
          forwardTo('/contact-details');
        }
      } else {
        if (this.props.profile.mobile && this.props.profile.first_name) {
          setMobile(this.props.profile.mobile);
          if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.fromHistory
          )
            forwardTo('/checkout', { fromHistory: true });
          forwardTo('/checkout');
        } else {
          forwardTo('/contact-details');
        }
      }
    }
  };

  handleMembership = () => {
    this.props.setOrderSubscriptionFlow();
    this.props.setOrderTypeMembership();
    forwardTo('/membership');
  };

  handleOrderItemClick = (item, index) => {
    this.setState({ quantityModal: { item, index } });
  };

  updateItemQuantity = () => {
    const { item, index } = this.state.quantityModal;
    if (this.state.quantityModal) {
      changeItemQuantity(index, item.quantity);
      this.setState({ quantityModal: null });
    }
  };

  onIncrementerUpdate = (newQuantity) => {
    this.setState({
      quantityModal: {
        ...this.state.quantityModal,
        item: {
          ...this.state.quantityModal,
          quantity: newQuantity,
        },
      },
    });
  };

  formatDayName = (name) => {
    if (name.includes('Today')) {
      name = 'Today'.toLowerCase();
    } else if (name.includes('Tomorrow')) {
      name = 'Tomorrow'.toLowerCase();
    } else {
      name = getOrderDate();
    }
    return name;
  };

  // findSelectedSlot = (dayInWeek, time) => {
  //   const restaurant = Basket.getRestaurant();
  //   const selectedDay =
  //     !isEmptyObject(restaurant) &&
  //       restaurant.delivery_times_json &&
  //       !isEmptyObject(restaurant.delivery_times_json) &&
  //       restaurant.delivery_times_json.slots &&
  //       !isEmptyObject(restaurant.delivery_times_json.slots)
  //       ? restaurant.delivery_times_json.slots[dayInWeek]
  //       : null;
  //   if (selectedDay) {
  //     const selectedSlot = selectedDay.find(
  //       (day) =>
  //         moment(day.start_time, 'HH:mm').format('HH:mm') ===
  //         moment(time, 'HH:mm a').format('HH:mm'),
  //     );
  //     return (
  //       moment(selectedSlot.start_time, 'HH:mm').format('h:mm a') +
  //       ' - ' +
  //       moment(selectedSlot.end_time, 'HH:mm').format('h:mm a')
  //     );
  //   }
  //   return null;
  // };

  drawContentHeader = (__, deliveryOption, orderType, deliveryAddress) => {
    let dateName = this.formatDayName(
      moment(getOrderDate(), 'dddd Do MMMM').calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
      }),
    );

    let dayInWeek = moment(getOrderDate(), 'dddd Do MMMM').format('dddd');

    if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-point') {
      return (
        <>
          <Title>{__(deliveryOption.label)}</Title>
          <Spacer size={1} />

          <div className="order-summary-header-wrapper"></div>
          <SmallText>
            {__('Ordering for')} {__('drop-off')} {__('at')} {getRestauranName()} {__(dateName)}{' '}
            {__('at')} {getOrderTime()}
            <br />
          </SmallText>
        </>
      );
    } else if (deliveryOption.id === 'charter-delivery' && !isEmptyObject(deliveryAddress)) {
      return (
        <>
          <Title>{__(deliveryOption.label)}</Title>
          <Spacer size={1} />

          <div className="order-summary-header-wrapper">
            <div>
              {/* <StrongText className="block">{__('Delivery from')}</StrongText>
			  <NormalText>{getRestauranName()}</NormalText> */}
              <StrongText className="block">{__('Delivery to')}</StrongText>
              <NormalText>
                {deliveryAddress.addressLine1}, {deliveryAddress.place} {deliveryAddress.postalCode}
              </NormalText>
            </div>
            <div>
              <StrongText> {getOrderTime()} </StrongText>
            </div>
          </div>
        </>
      );
    } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'table') {
      return (
        <>
          <Title>{__(deliveryOption.label)}</Title>
          <Spacer size={1} />

          <div className="order-summary-header-wrapper">
            <div>
              <StrongText className="block">{__('Table Oder At')}</StrongText>
              <NormalText>{getRestauranName()}</NormalText>
            </div>
            <div
              className="pointer"
              onClick={() => {
                this.setState({ tableNumberModalOpen: true });
              }}
            >
              <StrongText> {Basket.getTableNumber()} </StrongText>
            </div>
          </div>
        </>
      );
    } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'delivery') {
      return (
        <>
          <Title>{__(deliveryOption.label)}</Title>
          <Spacer size={1} />

          <div className="order-summary-header-wrapper">
            <div>
              <StrongText className="block">{__('Delivery to')}</StrongText>
              <NormalText>{deliveryAddress?.addressLine1}</NormalText>
              <StrongText className="second-heading block">{__('Delivery at')}</StrongText>
              {dateName} <br />{' '}
              {moment.unix(Basket.collection_time).tz('Europe/London').format('h:mm a')}
            </div>
          </div>
        </>
      );
    } else if (orderType === 'scheduled-collection') {
      return (
        <>
          <Title>{__('Collection')}</Title>
          <Spacer size={1} />
          <div className="order-summary-header-wrapper">
            <div>
              <StrongText className="block">{__('Collect from')}</StrongText>
              <NormalText>{getRestauranName()}</NormalText>
            </div>
            <div>
              <StrongText> {getOrderTime('h:mm a')}</StrongText>
              <StrongText> {getOrderTime('Do MMMM')}</StrongText>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Title>{__(orderType)}</Title>
          <Spacer size={1} />

          <div className="order-summary-header-wrapper">
            <div>
              <StrongText className="block">{__('Collect from')}</StrongText>
              <NormalText className="flex-wrap">
                {' '}
                <span>{getRestauranName()}</span>,&nbsp;
                <span>
                  {dateName} {__('at')} {getOrderTime('h:mm a')}
                </span>
              </NormalText>
            </div>
          </div>
        </>
      );
    }
  };

  handleApplyModal = (flag) => {
    const { history, auth } = this.props;
    const isAuth = auth.loggedIn;
    if (!isAuth) {
      this.props.dispatch(setProtectedReferrer(history.location.path));
      this.props.dispatch(restoreAuth());
      forwardTo('/login');
    } else {
      if (isWebConfig()) {
        this.setState({ applyPointsModalOpen: flag });
      } else {
        forwardTo('/apply-points');
      }
    }
  };

  handleRedeemVoucherModal = (flag) => {
    const { history, auth } = this.props;
    const isAuth = auth.loggedIn;
    if (!isAuth) {
      this.props.dispatch(setProtectedReferrer(history.location.path));
      this.props.dispatch(restoreAuth());
      forwardTo('/login');
    } else {
      if (isWebConfig()) {
        this.setState({ redeemVoucherModalOpen: flag });
      } else {
        forwardTo('/vouchers', { redeemVoucherCode: true });
      }
    }
  };
  checkoutOrder() {
    const { profile, __, dispatch } = this.props;
    const mobile = Basket.getMobile();
    if (/^\+[1-9]\d{1,14}$/.test(mobile) && this.state.mobile_value !== '') {
      this.setState({ phoneNumberInvalid: false });
      if (profile.can_pay_on_account && profile.is_corporate && !Basket.getPoNumber()) {
        this.setState({ showPoNumberAlert: true });
      } else if (Basket.getTotal() === 0 || Basket.getTotal() > 0.49) {
        this.handleSubmit(__);
      } else {
        dispatch(
          showToast(
            __('Please add more items to your basket. Amount to pay must be at least £0.50'),
            'warning',
          ),
        );
      }
    } else {
      this.setState({ phoneNumberInvalid: true });
      dispatch(showToast(__('Please insert your phone number in correct format.'), 'warning'));
    }
  }
  render() {
    const { __, profile, auth, dispatch, isCollection } = this.props;
    const {
      voucherFieldVisible,
      quantityModal,
      allergenModalOpen,
      applyPointsModalOpen,
      mobile,
      mobile_code,
      mobile_value,
      po_number,
      redeemVoucherModalOpen,
      voucherCode,
      itemIsDisabled,
    } = this.state;
    const valid = validateProfileData(profile).isValid;
    const allergens = getAllergen() || [];
    const contactLabelArrg = isWebConfig() ? { position: 'floating' } : { slot: 'start' };
    const appliedVouchers = Basket.getAppliedVocuher();
    const basketInstance = this.props.basketInstance || Basket;
    const isAuth = auth.loggedIn;
    return (
      <>
        <div className="absolute-content flex-row-wrapper click-collect-content scrollable-y">
          <div className="checkout">
            {this.drawContentHeader(
              __,
              Basket.getDeliveryOption(),
              Basket.getOrderType(),
              Basket.getDeliveryAddress(),
            )}
            <Spacer size={1} />
            <div className="input-field-container">
              <NormalText>{__('Contact Number')}</NormalText>
              <div>
                <IonItem
                  lines="none"
                  className={`input-field-wrapper  inline-input-field-wrapper ${this.state.phoneNumberInvalid ? 'invalid' : ''
                    }`}
                >
                  <div>
                    <SelectOption
                      onSet={(e, val) => {
                        this.handleInput('mobile_code', val.getVal());
                        this.handleInput(
                          'mobile',
                          `${val.getVal()}${mobile_value ? mobile_value : ''}`,
                        );
                      }}
                      display="center"
                      value={mobile_code}
                      data={getConfig().country_code_data}
                      onInit={(e, val) => {
                        if (!mobile_code) {
                          this.handleInput('mobile_code', val._tempWheelArray[0]);
                          this.handleInput(
                            'mobile',
                            `${val._tempWheelArray[0]}${mobile_value ? mobile_value : ''}`,
                          );
                        }
                      }}
                    />
                  </div>
                  <IonInput
                    onIonInput={(e) => {
                      this.handleInput('mobile_value', e.target.value);
                      this.handleInput('mobile', `${mobile_code}${e.target.value}`);
                    }}
                    clearInput
                    required={false}
                    type="tel"
                    pattern="tel"
                    inputmode="tel"
                    placeholder="XXXXXXXXXXXX"
                    value={mobile_value}
                  ></IonInput>
                </IonItem>
              </div>
            </div>
            <Spacer size={1} />
            {itemsCount() > 0 ? (
              <OrderContent
                showAddItems={true}
                handleOrderItemClick={this.handleOrderItemClick.bind(this)}
                showDiscountAlert={this.handleRemoveDiscount}
              />
            ) : (
              <IonItem lines="none">
                <div
                  tabIndex="-1"
                  className="sectiontitle"
                  style={{ width: '100vh', textAlign: 'center' }}
                >
                  {__('No items')}
                </div>
              </IonItem>
            )}
            {!basketInstance.discount && isCollection && (
              <div className="ion-text-center">
                <IonButton
                  expand="block"
                  className={'tertiary-btn-order'}
                  onClick={this.handleMembership}
                >
                  {__('Save 20% on this order')}
                </IonButton>
                <SmallText>{__('Join Pure +more today')}</SmallText>
              </div>
            )}

            {isAuth && !valid && (
              <div className="validate-content">
                <ValidateButton />
                {isAuth && !valid && getConfig().appType.hasEmailValidationEnabled ? (
                  <div className="verified-content">
                    <SmallText color="grey">
                      {__('You can earn, but not redeem points until your account is verified')}
                    </SmallText>
                  </div>
                ) : null}
              </div>
            )}

            {/* {getConfig().flags?.showVoucherLink && (
              <IonButton
                fill="clear"
                expand="block"
                className="link underlined"
                color="dark"
                onClick={() => this.handleRedeemVoucherModal(true)}
              >
                {__('Redeem Voucher Code')}
              </IonButton>
            )} */}
            {profile.is_corporate && (
              <>
                <Spacer size={1} />
                <div className="input-field-container">
                  <NormalText>{__('PO Number')}</NormalText>
                  <IonItem className="input-field-wrapper" lines="none">
                    <div tabIndex="-1"></div>

                    <IonInput
                      onIonInput={(e) => this.handleInput('po_number', e.target.value)}
                      clearInput
                      required={true}
                      type="text"
                      value={po_number}
                    />
                  </IonItem>
                </div>

                <NormalText>
                  {__('PO numbers only. This section is not viewed by the restaurant')}
                </NormalText>
                <Spacer />
              </>
            )}
            <Spacer size={1} />
            <div className="order-summary-additional-buttons-wrapper">
              {allergens.length > 0 ? (
                <IonButton
                  fill="clear"
                  expand="block"
                  className="order-summary-additional-button"
                  color="dark"
                  onClick={() => this.setState({ allergenModalOpen: true })}
                >
                  <NormalText>{__('View allergen information')}</NormalText>
                  <IonIcon icon={chevronForwardOutline}></IonIcon>
                </IonButton>
              ) : null}
              <IonButton
                disabled={itemsCount() === 0 || !isMinimumOrderTotalSatisfied()}
                className={`order-summary-additional-button ${itemsCount() === 0 || !valid ? 'disabled' : ''
                  }`}
                onClick={() => {
                  if ((itemsCount() === 0 || !valid) && isAuth) {
                    dispatch(setModal('isVerfiedModalOpen', true));
                  } else {
                    forwardTo('/apply-vouchers');
                  }
                }}
                fill="clear"
                expand="block"
                color="dark"
              >
                <NormalText>
                  {appliedVouchers && appliedVouchers.length > 0
                    ? __('Replace Voucher')
                    : __('Apply Vouchers')}
                </NormalText>
                <IonIcon icon={chevronForwardOutline}></IonIcon>
              </IonButton>
              <IonButton
                disabled={
                  itemsCount() === 0 ||
                  getConfig().general.redeemPointsMin > this.props.availableBalance ||
                  getConfig().general.redeemPointsMin > basketInstance.getTotal() * 100 ||
                  !isMinimumOrderTotalSatisfied()
                }
                className={`order-summary-additional-button ${itemsCount() === 0 || !valid || profile.available_balance === 0 ? 'disabled' : ''
                  }`}
                onClick={() => {
                  if (profile.available_balance === 0) {
                    return null;
                  }
                  if ((itemsCount() === 0 || !valid) && isAuth) {
                    dispatch(setModal('isVerfiedModalOpen', true));
                  } else {
                    this.handleApplyModal(true);
                  }
                }}
                fill="clear"
                expand="block"
                color="dark"
              >
                <NormalText>{__('Redeem Points')}</NormalText>
                <IonIcon icon={chevronForwardOutline}></IonIcon>
              </IonButton>

              {getConfig().flags?.showVoucherLink && (
                <>
                  <IonButton
                    fill="clear"
                    expand="block"
                    color="dark"
                    className={`order-summary-additional-button ${voucherFieldVisible ? 'order-summary-additional-button-opened' : ''
                      }`}
                    onClick={() => this.setState({ voucherFieldVisible: !voucherFieldVisible })}
                  >
                    <NormalText>{__('Redeem Voucher Code')}</NormalText>
                    <IonIcon icon={chevronForwardOutline}></IonIcon>
                  </IonButton>
                </>
              )}
            </div>
            {voucherFieldVisible && (
              <div className="redeem-voucher-field-wrapper order-summary-redeem-voucher">
                <IonItem className="input-field-wrapper" lines="none">
                  <IonInput
                    value={voucherCode}
                    onIonInput={(e) =>
                      this.setState({ voucherCode: e.target.value.toUpperCase() })
                    }
                    placeholder={__('Voucher code')}
                  />
                </IonItem>
                <IonButton color="primary">{__('Submit')}</IonButton>
              </div>
            )}
            <Spacer size={1} />
            <IonButton
              disabled={itemsCount() === 0}
              onClick={() => this.checkoutOrder()}
              expand="block"
              className={'checkout-btn ' + (!isMinimumOrderTotalSatisfied() ? 'greyed' : '')}
            >
              {__('Checkout')}
            </IonButton>
          </div>
        </div>

        <Modal
          title={__('Change quantity')}
          action={this.updateItemQuantity}
          actionLabel={__('Change')}
          isOpen={!!quantityModal}
          onDidDismiss={() => this.setState({ quantityModal: null })}
        >
          {quantityModal && quantityModal.item ? (
            <Incrementer
              allowNegative={false}
              quantity={quantityModal.item.quantity}
              onUpdate={this.onIncrementerUpdate}
            />
          ) : null}
        </Modal>
        <Modal
          cssClass="allergen-modal"
          isOpen={allergenModalOpen}
          onDidDismiss={() => this.setState({ allergenModalOpen: false })}
        >
          <div className="absolute-content flex-row-wrapper">
            <div className="scrollable-y rhino">
              <Title>{__('Allergen Information')}</Title>
              <Spacer />
              {getItems().map((item, index) => {
                let data = allergens.find((allergen) => allergen[1].sku === item.item.sku);
                if (data && data.length > 0) {
                  return (
                    <>
                      <div className='allergen-wrapper' key={'allergen-' + index}>
                        <Subtitle className="block">
                        {item.quantity}x {Basket.getProductName(item.item, this.props.profile)}
                        </Subtitle>
                        <PillGroup items={data?.[0]?.allergens} borderColor="primary" />

                        <div>
                          {item?.selectedChoices.filter(subItem => subItem.length > 0).map(subItem => (
                            <>
                              {subItem.map(item => {
                                const data = allergens?.find((allergen) => allergen?.[1]?.sku === item?.sku)
                                if (data && data.length > 0) {
                                  return (
                                    (
                                      <div>
                                        <Sectiontitle>1x {Basket.getProductName(item, this.props.profile)}</Sectiontitle>
                                        <PillGroup items={data?.[0]?.allergens} borderColor="primary" />

                                      </div>
                                    )
                                  )
                                }
                              })}
                            </>
                          ))}
                        </div>
                      </div>
                      <Spacer size={2} />
                    </>
                  );
                }
                return null;
              })}
            </div>
            <div className="flex-min centered">
              <Spacer />
              <IonButton
                fill="clear"
                className="link underlined"
                color="dark"
                onClick={() => this.setState({ allergenModalOpen: false })}
              >
                {__('Hide allergen information')}
              </IonButton>
            </div>
          </div>
        </Modal>
        <Modal
          cssClass="apply-points-modal"
          isOpen={applyPointsModalOpen}
          onDidDismiss={() => this.handleApplyModal(false)}
        >
          <ApplyPoints
            handleApplyModal={this.handleApplyModal}
            applyPointsModalOpen={applyPointsModalOpen}
          />
        </Modal>
        <Modal
          cssClass="apply-points-modal"
          isOpen={redeemVoucherModalOpen}
          onDidDismiss={() => this.handleRedeemVoucherModal(false)}
        >
          <Vouchers isVoucherType={true} />
        </Modal>
        <IonAlert
          isOpen={itemIsDisabled}
          header={__('Warning') + '!'}
          message={__('Some items are no longer available and have been removed from your order')}
          buttons={[
            {
              text: __('OK'),
              handler: () => {
                this.setState({ itemIsDisabled: false });
              },
            },
          ]}
        />
        <IonAlert
          isOpen={this.state.showPoNumberAlert}
          onDidDismiss={() => this.setState({ showPoNumberAlert: false })}
          message={__('Please enter a PO number. If not required, enter “NA”')}
          buttons={[
            {
              text: __('OK'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.setState({ showPoNumberAlert: false }),
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const { basketUpdated, deliveryOption, selectedSubscription } = store.orders;
  const { auth } = store.profile;
  const { ikentooMenusForLocation, ikentooMenu, restaurants } = store.restaurants;

  return {
    restaurants,
    basketUpdated,
    profile: store.profile.profile,
    cards: store.orders.cards || [],
    deliveryOption,
    itemAllergens: store.restaurants.itemAllergens,
    auth,
    ikentooMenusForLocation,
    ikentooMenu,
    isCollection: deliveryOption !== null && deliveryOption.id === 'collection',
    selectedSubscription,
    subscriptions: store.subscription.subscriptions,
    availableBalance: deepIsDefined(store, 'profile.profile.available_balance')
      ? store.profile.profile.available_balance
      : 0,
  };
};

const mapDispatchToProps = {
  setOrderTypeMembership,
  setOrderTypeRegular,
  setOrderSubscriptionFlow,
  setSelectedSubscription,
  getSubscriptions,
};

export const OrderSummary = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation(OrderSummaryRaw)));

class OrderSummaryWrapped extends React.Component {
  backHandler = () => {
    if (this.props.location?.state?.fromItemDetails) {
      // skip item details page when we going back from order summary
      go(-2);
    } else if (this.props.location?.state?.skipBackToThePreviousPage) {
      forwardTo('/order');
    } else if (this.props.location?.state?.fromHistory) {
      forwardTo('/history');
    } else {
      goBack();
    }
  };
  render() {
    const { __ } = this.props;
    return (
      <Loading>
        <Layout
          hideSecondToolbar
          color="transparent"
          headerTitle={__('Order Summary')}
          backHandler={this.backHandler}
          scrollY={false}
        >
          <OrderSummary />
        </Layout>
      </Loading>
    );
  }
}

export default withTranslation(OrderSummaryWrapped);
