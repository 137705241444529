import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonCheckbox,
  IonItem,
  IonList,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonButton,
  IonIcon,
  IonAlert,
  IonTextarea,
  IonRow,
} from '@ionic/react';
import { arrowBack, chevronDown, chevronUp } from 'ionicons/icons';
import Layout from '../../components/layout';
import Incrementer from '../../components/incrementer';
import Pullup from '../../components/pullup';
import {
  Title,
  Sectiontitle,
  SmallText,
  Spacer,
  FlexSpacer,
  Hr,
  NormalText,
  StrongText,
} from '../../components/common';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import {
  makeKey,
  isDefined,
  deepCopy,
  goBack,
  isWebConfig,
  forwardTo,
  isEmptyObject,
  parseAllergenData,
  isObject,
  isTaxActiveForOrderType,
  getItemTax,
  doesItemHaveTax,
} from '../../lib/utils';
import { PillGroup } from '../../components/pill';
import { storeItemWeb } from '../../store/actions';
import { getConfig } from '../../appConfig';
import './index.css';

const {
  formatPrice,
  _calculateItemPrice,
  validateItem,
  addToBasket,
  getProductName,
  getProductDescription,
  isProductEnabled,
  isChoicesGroupValid,
  setAllergen,
} = Basket;

const noImage = () => <div className="item-details-background"></div>;

class itemDetailsContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      price: 0,
      selectedChoices: [],
      validationErrors: [],
      instructions: '',
      showValidationAlert: false,
      allergensCodes: [],
      nutritionalInfoVisible: false,
      ingredientsVisible: false,
      nameVisible: true,
      excludeTaxes: false,
      orderType: '',
    };
  }

  componentDidMount() {
    const { profile, allergens, item } = this.props;
    let quantity = this.state.quantity;
    let selectedChoices = [];
    let validationErrors = [];

    if (item.menuDealGroups && item.menuDealGroups.length > 0) {
      selectedChoices = Array(item.menuDealGroups.length).fill([]);
      validationErrors = Array(item.menuDealGroups.length).fill(null);
    }
    let price = _calculateItemPrice({ item, quantity });
    let newArr = parseAllergenData(profile, item, allergens);
    const basketInstance = this.props.basketInstance || Basket;
    const orderType = basketInstance.getOrderTypeRaw();
    const excludeTaxes = profile.is_corporate
      ? isTaxActiveForOrderType(orderType)
      : doesItemHaveTax(item);
    this.setState({
      price,
      selectedChoices,
      validationErrors,
      allergensCodes: newArr,
      excludeTaxes,
      orderType,
    });

    let updatedDefaultSelectedOption = deepCopy(selectedChoices);
    const menuDealGroups = item.menuDealGroups ? item.menuDealGroups : [];
    {
      menuDealGroups.map((menuGroupItem, index) => {
        const multiSelectionPermitted = menuGroupItem.multiSelectionPermitted;
        updatedDefaultSelectedOption = this.drawDefaultGroupChoices(
          updatedDefaultSelectedOption,
          menuGroupItem,
          multiSelectionPermitted,
          index,
        );
        if (menuDealGroups.length - 1 === index) {
          this.setDefaultOption(updatedDefaultSelectedOption);
        }
      });
    }
  }

  onIncrementerUpdate = (newQuantity) => {
    this.setState({
      quantity: newQuantity,
      price: _calculateItemPrice({
        item: this.props.item,
        quantity: newQuantity,
        selectedChoices: this.state.selectedChoices,
      }),
    });
  };

  handleInputChange = (groupIndex, sku, multiSelectionPermitted, event, isBundle = false) => {
    const item = this.props.item;
    const { quantity, selectedChoices } = this.state;
    const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const selectedGroup = allGroups[groupIndex];

    if (selectedGroup && sku !== null) {
      let selectedChoice = selectedGroup.items.find((i) => i.sku === sku);

      if (!selectedChoice) {
        allGroups[groupIndex].bundleModifiers.every((product) => {
          if (product.length > 0) {
            product.every((modifierGroup) => {
              if (modifierGroup && modifierGroup.items.length > 0) {
                modifierGroup.items.every((modifier) => {
                  if (modifier) {
                    if (modifier.sku === sku) {
                      selectedChoice = modifier;
                      return false;
                    }
                    return true;
                  } else {
                    return true;
                  }
                });
              } else {
                return true;
              }
            });
          } else {
            return true;
          }
        });
      }
      let updatedSelectedChoices = deepCopy(selectedChoices);
      if (multiSelectionPermitted) {
        //checkbox
        if (event.target.checked) {
          updatedSelectedChoices[groupIndex].push(selectedChoice);
        } else {
          updatedSelectedChoices[groupIndex] = updatedSelectedChoices[groupIndex].filter(
            (i) => i.sku !== selectedChoice.sku,
          );
        }
      } else {
        //radio
        if (!selectedChoice) {
          if (!isBundle) {
            if (updatedSelectedChoices[groupIndex].length > 1) {
              updatedSelectedChoices[groupIndex] = [updatedSelectedChoices[groupIndex][0]];
            } else {
              updatedSelectedChoices[groupIndex] = [];
            }
          } else {
            updatedSelectedChoices[groupIndex] = [updatedSelectedChoices[groupIndex][0]];
          }
        } else {
          if (!isBundle) {
            if (!updatedSelectedChoices[groupIndex].find((i) => i.sku === selectedChoice.sku))
              updatedSelectedChoices[groupIndex] = [selectedChoice];
          } else {
            updatedSelectedChoices[groupIndex].push(selectedChoice);
          }
        }
      }

      this.setState({ selectedChoices: updatedSelectedChoices }, () => {
        const { selectedChoices } = this.state;
        //recalculate item price on every menu deal choice change
        this.setState(
          { price: _calculateItemPrice({ item, quantity, selectedChoices }, true) },
          () => {
            const validationErrors = validateItem(this.constructBasketItem());
            this.setState({ validationErrors: validationErrors.errors });
          },
        );
      });
    }
  };

  handleSubItem = (
    groupIndex,
    modifier,
    multiSelectionPermitted,
    isChecked,
    isBundle = false,
    selectedGroupIndex,
  ) => {
    const sku = modifier.sku;
    const item = this.props.item;
    let { quantity, selectedChoices } = this.state;
    const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const selectedGroup = allGroups[groupIndex];
    let updateState = true;
    if (selectedGroup && sku !== null) {
      let selectedChoice = selectedGroup.items.find((i) => i.sku === sku);
      let selectedItemWithSubItems;
      if (!selectedChoice) {
        selectedChoice = modifier;
        selectedItemWithSubItems = selectedGroup.items[selectedGroupIndex];
      }
      let updatedSelectedChoices = deepCopy(selectedChoices);
      if (multiSelectionPermitted) {
        //checkbox
        if (isChecked) {
          updatedSelectedChoices[groupIndex].forEach((item) => {
            if (!item.subItems) {
              item.subItems = [];
            }
            if (
              item.sku === selectedItemWithSubItems.sku &&
              !item.subItems.find((subItem) => subItem.sku === selectedChoice.sku)
            ) {
              item.subItems.push(selectedChoice);
              updateState = true;
            } else {
              updateState = false;
            }
          });
        } else {
          updatedSelectedChoices[groupIndex].forEach((item) => {
            if (!item.subItems) {
              item.subItems = [];
            }
            if (item.sku === selectedItemWithSubItems.sku) {
              item.subItems = item.subItems.filter((subItem) => subItem.sku !== selectedChoice.sku);
            }
            updateState = true;
          });
        }
      } else {
        //radio
        if (!selectedChoice) {
          if (!isBundle) {
            if (updatedSelectedChoices[groupIndex].length > 1) {
              updatedSelectedChoices[groupIndex] = [updatedSelectedChoices[groupIndex][0]];
            } else {
              updatedSelectedChoices[groupIndex] = [];
            }
          } else {
            updatedSelectedChoices[groupIndex] = [updatedSelectedChoices[groupIndex][0]];
          }
        } else {
          if (!isBundle) {
            if (!updatedSelectedChoices[groupIndex].find((i) => i.sku === selectedChoice.sku))
              updatedSelectedChoices[groupIndex] = [selectedChoice];
          } else {
            updatedSelectedChoices[groupIndex].push(selectedChoice);
          }
        }
      }
      if (updateState) {
        const price = _calculateItemPrice(
          { item, quantity, selectedChoices: updatedSelectedChoices },
          true,
        );
        this.setState(
          {
            selectedChoices: updatedSelectedChoices,
            price: price,
          },
          () => {
            const validationErrors = validateItem(this.constructBasketItem());
            this.setState({ validationErrors: validationErrors.errors });
          },
        );
      }
    }
  };

  setDefaultOption = (updatedDefaultSelectedOption) => {
    const item = this.props.item;
    const { quantity } = this.state;

    this.setState({ selectedChoices: updatedDefaultSelectedOption }, () => {
      const { selectedChoices } = this.state;
      //recalculate item price on every menu deal choice change
      this.setState({ price: _calculateItemPrice({ item, quantity, selectedChoices }) }, () => {
        const validationErrors = validateItem(this.constructBasketItem());
        this.setState({ validationErrors: validationErrors.errors });
      });
    });
  };

  drawDefaultGroupChoices = (
    updatedDefaultSelectedOption,
    menuGroupItem,
    multiSelectionPermitted,
    groupIndex,
  ) => {
    const choices = menuGroupItem.items || [];
    const { selectedChoices } = this.state;
    choices.map((item) => {
      const { defaultQuantity } = item;
      let isOptionSelected = (selectedChoices[groupIndex] || []).find((i) => {
        return isObject(i);
      });
      if (defaultQuantity && !isOptionSelected) {
        if (multiSelectionPermitted) {
          //checkbox
          updatedDefaultSelectedOption[groupIndex].push(item);
        } else {
          //radio
          if (!item) {
            updatedDefaultSelectedOption[groupIndex] = [];
          } else {
            updatedDefaultSelectedOption[groupIndex] = [item];
          }
        }
      }
    });
    return updatedDefaultSelectedOption;
  };

  drawGroupChoices = (menuGroupItem, multiSelectionPermitted, groupIndex) => {
    const choices = menuGroupItem.items || [];
    const bundleModifiers = menuGroupItem.bundleModifiers || [];
    const { selectedChoices, excludeTaxes, orderType } = this.state;
    const { profile, __ } = this.props;
    const allChoices = choices.map((item, choiceIndex) => {
      const { sku } = item;
      let productPrice = item.productPrice;
      if (excludeTaxes) {
        productPrice = parseFloat(getItemTax(item, orderType)?.calculatedTaxPrice || 0);
      }
      const isChecked = !!(selectedChoices[groupIndex] || []).find((i) => {
        return i.sku === sku;
      });
      if (item.sku === undefined || !isProductEnabled(item)) {
        return <span key={item.sku + '_disabled'} />;
      }
      const subModifierGroup = () => {
        const subModifiers = bundleModifiers[choiceIndex]?.[0]?.items || [];
        const subMultiSelectionPermitted =
          bundleModifiers[choiceIndex]?.[0]?.multiSelectionPermitted;

        if (subModifiers.length > 0) {
          let allModifiers = subModifiers.map((modifier, modifierIndex) => {
            let isModiferChecked = !!(selectedChoices[groupIndex] || []).find(
              (i) => i.sku === modifier.sku,
            );
            if (!isModiferChecked && selectedChoices[groupIndex] && selectedChoices.length > 0) {
              let selectedChoice = (selectedChoices[groupIndex] || []).find(
                (choice) => choice.sku === item.sku,
              );
              if (selectedChoice.subItems) {
                isModiferChecked = selectedChoice.subItems.find((i) => modifier.sku === i.sku);
              } else {
                isModiferChecked = false;
              }
            }
            if (modifier.sku === undefined || !isProductEnabled(modifier)) {
              return <span key={modifier.sku + '_disabled'} />;
            }
            let modifierProductPrice = modifier.productPrice;
            if (excludeTaxes) {
              modifierProductPrice = parseFloat(
                getItemTax(modifier, orderType)?.calculatedTaxPrice || 0,
              );
            }
            return (
              <IonItem
                className={`sub-item ${choiceIndex == choices.length - 1 ? 'last-item' : ''}`}
                lines="none"
                key={makeKey(modifierIndex, modifier.sku, choiceIndex)}
                onClick={() => {                  
                  if (subMultiSelectionPermitted) {
                    this.handleSubItem(
                      groupIndex,
                      modifier,
                      subMultiSelectionPermitted,
                      { target: { checked: !isChecked } },
                      true,
                      choiceIndex,
                    );
                  } else {
                    this.handleInputChange(groupIndex, sku, multiSelectionPermitted, { target: { value: choiceIndex } });
                  }
                }}
              >
                {subMultiSelectionPermitted ? (
                  <IonCheckbox
                    color="secondary"
                    slot="start"
                    checked={isModiferChecked || false}
                    /* onIonChange={(event) => {
                      this.handleSubItem(
                        groupIndex,
                        modifier,
                        subMultiSelectionPermitted,
                        event.target.checked,
                        true,
                        choiceIndex,
                      );
                    }} */
                  />
                ) : (
                  <IonRadio
                    slot="start"
                    className="details-radio"
                    color={'secondary'}
                    checked={isModiferChecked}
                    value={modifier.sku}
                    name="bundle-product"
                  />
                )}

                <IonLabel className="ion-text-wrap">
                  <StrongText className="single-item item-details-product-label">
                    {__(getProductName(modifier, profile))}
                  </StrongText>
                  {isDefined(modifier.itemRichData) &&
                      isDefined(modifier.itemRichData.allergenCodes) &&
                      modifier.itemRichData.allergenCodes.length > 0 ? (
                      <>
                        <SmallText className='block capitalize'><span className='primary-color'>{__('Allergens')}: </span>{modifier.itemRichData.allergenCodes.join(' • ')}</SmallText>
                      </>
                    ) : null}
                </IonLabel>

                <p>{formatPrice(modifierProductPrice)}</p>
              </IonItem>
            );
          });

          return subMultiSelectionPermitted ? (
            <div className="bundle-modifier-group">
              {this.drawGroupLabel(bundleModifiers[choiceIndex][0], groupIndex)}

              {allModifiers}
            </div>
          ) : (
            <div className="bundle-modifier-group">
              {this.drawGroupLabel(bundleModifiers[choiceIndex][0], groupIndex)}

              <IonRadioGroup
                name="bundle-product"
                allowEmptySelection={true}
                value={selectedChoices[groupIndex]?.[1]?.sku || null}
                onIonChange={(event) => {
                  setTimeout(() => {
                    this.handleInputChange(
                      groupIndex,
                      event.target.value,
                      subMultiSelectionPermitted,
                      event,
                      true,
                      choiceIndex,
                    );
                  }, 1);
                }}
              >
                {allModifiers}
              </IonRadioGroup>
            </div>
          );
        }
      };

      return (
        <>
          <IonItem 
            lines="none" 
            key={makeKey(choiceIndex, sku, groupIndex)}
            onClick={() => {              
              if (multiSelectionPermitted) {
                this.handleInputChange(groupIndex, sku, multiSelectionPermitted, { target: { checked: !isChecked } });
              } else {
                this.handleInputChange(groupIndex, sku, multiSelectionPermitted, { target: { value: choiceIndex } });
              }
            }}
          >
            <div tabIndex="-1"></div>

            {multiSelectionPermitted ? (
              <IonCheckbox
                color="secondary"
                slot="start"
                checked={isChecked}
               /*  onIonChange={(event) => {
                  this.handleInputChange(groupIndex, sku, multiSelectionPermitted, event);
                }} */
              />
            ) : (
              <IonRadio
                slot="start"
                className="details-radio"
                color={ 'secondary'}
                value={sku}
                checked={isChecked}
                name="modifier-group"
              />
            )}
            <IonLabel className="ion-text-wrap">
              <Sectiontitle className="single-item item-details-product-label">
                {__(getProductName(item, profile))}
              </Sectiontitle>
              {isDefined(item.itemRichData) &&
                      isDefined(item.itemRichData.allergenCodes) &&
                      item.itemRichData.allergenCodes.length > 0 ? (
                      <>
                        <SmallText className='block capitalize '><span className='primary-color'>{__('Allergens')}: </span>{item.itemRichData.allergenCodes.join(' • ')}</SmallText>
                      </>
                    ) : null}
            </IonLabel>
            <p>{formatPrice(productPrice)}</p>
          </IonItem>
          {isChecked && subModifierGroup()}
        </>
      );
    });
    if (multiSelectionPermitted) {
      return <div className="item-details-group-wrapper box-wrapper box-list">{allChoices}</div>;
    } else {
      //radio
      return (
        <div className="item-details-group-wrapper box-wrapper box-list">
          <IonRadioGroup
            name="modifier-group"
            allowEmptySelection={true}
            value={selectedChoices[groupIndex]?.[0]?.sku || null}
            onIonChange={(event) => {
              if (
                choices.find((i) => {
                  return i.sku === event.target.value;
                }) ||
                event.target.value === undefined
              ) {
                /*
                setTimeout(() => {
                  this.handleInputChange(
                    groupIndex,
                    event.target.value,
                    multiSelectionPermitted,
                    event,
                  );
                }, 0); */
              }
            }}
          >
            {allChoices}
          </IonRadioGroup>
        </div>
      );
    }
  };

  drawGroupLabel = (menuGroupItem, groupIndex) => {
    const { validationErrors, excludeTaxes } = this.state;
    const { __ } = this.props;
    const { description, min, max } = menuGroupItem;
    return (
      <>
        <IonItem lines="none">
          <div tabIndex="-1" className="sectiontitle">
            <StrongText>{description}</StrongText>
            <Spacer size={1} />
            <div>
              {!isDefined(min) && !isDefined(max) ? null : (
                <SmallText>
                  {isDefined(min) ? __('min') + ':' + min + ',' : null}{' '}
                  {isDefined(max) ? __('max') + ':' + max : null}
                </SmallText>
              )}
              {excludeTaxes ? (
                <SmallText className="exclude-taxes-text">{__('Exc VAT')}</SmallText>
              ) : null}
            </div>
          </div>
        </IonItem>
        {validationErrors[groupIndex] ? (
          <div className="field-error">{__(validationErrors[groupIndex])}</div>
        ) : null}
      </>
    );
  };

  drawMenuDealGroups = (menuGroupItem, index) => {
    // multiSelectionPermitted = true  --> only one item must be selected
    const multiSelectionPermitted = menuGroupItem.multiSelectionPermitted;
    if (isChoicesGroupValid(menuGroupItem)) {
      if (isDefined(multiSelectionPermitted)) {
        return (
          <div className="item-modifier-group" key={index}>
            {this.drawGroupLabel(menuGroupItem, index)}
            {this.drawGroupChoices(menuGroupItem, multiSelectionPermitted, index)}
          </div>
        );
      } else {
        return this.drawGroupLabel(menuGroupItem);
      }
    }
  };

  constructBasketItem = () => {
    const { item } = this.props;
    const { quantity, selectedChoices, instructions } = this.state;
    // const item = item

    return {
      item,
      quantity,
      selectedChoices,
      instructions,
    };
  };

  addToOrder = () => {
    const newBasketItem = this.constructBasketItem();
    const validationErrors = validateItem(newBasketItem);
    const { item } = this.props;
    if (validationErrors.errorCount > 0) {
      this.setState({ validationErrors: validationErrors.errors }, () => {
        this.setShowValidationAlert(true);
      });
    } else {
      if (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)) {
        this.props.dispatch(storeItemWeb(newBasketItem));
        forwardTo('/delivery-options');
      } else {
        addToBasket(newBasketItem);
        if (isWebConfig()) {
          this.props.closeModal();
        } else {
          goBack();
        }
      }
    }

  	let allergensCodes = item.itemRichData ? (item.itemRichData.allergenCodes ? item.itemRichData.allergenCodes : []) : [];
		const modifierCodes = [...new Set(newBasketItem.selectedChoices.map((group) => group.map((item) => item?.itemRichData?.allergenCodes).flat()).flat())].filter(Boolean);
    if (allergensCodes.length > 0 || modifierCodes.length > 0) {
			let allergensData = [{ allergens: this.state.allergensCodes }, { sku: item.sku }];
			if (isDefined(newBasketItem.selectedChoices)) {
				newBasketItem.selectedChoices.forEach((group) => {
					group.forEach((item) => {
						if (isDefined(item.itemRichData) && isDefined(item.itemRichData.allergenCodes) && item.itemRichData.allergenCodes.length > 0) {
							setAllergen([{ allergens: item.itemRichData.allergenCodes }, { sku: item.sku }]);
						}
					});
				});
			}
			setAllergen(allergensData);
		}

    // let allergensCodes =
    //   item &&
    //   item.itemRichData &&
    //   item.itemRichData.allergenCodes &&
    //   item.itemRichData.allergenCodes.length > 0
    //     ? item.itemRichData.allergenCodes
    //     : [];
    // if (allergensCodes.length > 0) {
    //   let allergensData = [{ allergens: this.state.allergensCodes }, { sku: item.sku }];
    //   setAllergen(allergensData);
    // }
  };

  instructionsChange = (event) => this.setState({ instructions: event.target.value });

  setShowValidationAlert = (flag) => this.setState({ showValidationAlert: flag });
  drawNutritionalInfo = (__, nutritionalInfo) => {
    return (
      <div className="nutrition-accordion-wrapper">
        {this.drawAccortianButton(
          () =>
            this.setState({
              nutritionalInfoVisible: !this.state.nutritionalInfoVisible,
              ingredientsVisible: false,
            }),
          __('Nutritional Info'),
          this.state.nutritionalInfoVisible,
        )}
        {this.state.nutritionalInfoVisible && (
          <div>
            {this.drawNutritionalTable(__, nutritionalInfo.items)}
            {this.drawAccortianButton(
              () => this.setState({ ingredientsVisible: !this.state.ingredientsVisible }),
              __('Ingredients'),
              this.state.ingredientsVisible,
            )}
            {this.state.ingredientsVisible && this.drawIngredients(nutritionalInfo.ingredients)}
          </div>
        )}
      </div>
    );
  };
  drawAccortianButton = (onClick, label, visible) => {
    return (
      <>
        <div className="accordian-button" onClick={onClick}>
          <span>{label}</span>
          <IonIcon icon={visible ? chevronUp : chevronDown} />
        </div>
      </>
    );
  };
  drawIngredients = (text) => {
    const { nutritionData } = this.props;
    if (this.state.selectedChoices.length > 0) {
      const ingredientsList = this.state.selectedChoices[0].map((el) => {
        return nutritionData.find((value) => value.sku === el.sku)?.ingredients || '';
      });
      ingredientsList.map((value) => {
        text += value;
      });
    }
    return <div className="ingredients-text" dangerouslySetInnerHTML={{ __html: text }}></div>;
  };
  drawNutritionalTable = (__, items) => {
    const { nutritionData } = this.props;
    let nutritions = items;
    if (this.state.selectedChoices.length > 0 && !isEmptyObject(this.state.selectedChoices[0])) {
      let nutritionDataItems = this.state.selectedChoices[0].map((i) => {
        return nutritionData.find((value) => value.sku === i.sku)?.items;
      });
      nutritionDataItems = !nutritionDataItems[0] ? [] : nutritionDataItems;
      const totalamount = (totalGroup, indexOfElement, sort) => {
        let sum = '';
        totalGroup.map((item) => {
          let stringToNumOfPastValue = sum.match(/[a-z]+|[^a-z]+/gi)
            ? +sum.match(/[a-z]+|[^a-z]+/gi)?.[0]
            : '';
          let additionalNut =
            item[indexOfElement][sort] === 'n/a'
              ? ''
              : +item[indexOfElement][sort].match(/[a-z]+|[^a-z]+/gi)?.[0] || 0;
          sum =
            (parseFloat(stringToNumOfPastValue + additionalNut).toFixed(2) * 100) / 100 +
              item[indexOfElement][sort].match(/[a-z]+|[^a-z]+/gi)?.[1] || '';
        });
        return sum;
      };

      nutritions = items.map((item, index) => {
        let newItem = {
          item: '',
          amountPer100: '',
          amountPerServing: '',
        };
        newItem.item = item.item;
        newItem.amountPer100 = totalamount([items, ...nutritionDataItems], index, 'amountPer100');
        newItem.amountPerServing = totalamount(
          [items, ...nutritionDataItems],
          index,
          'amountPerServing',
        );
        return newItem;
      });
    }
    return (
      <table className="nutrition-table">
        <thead>
          <tr>
            <th>{__('Table of Nutritional Information')}</th>
            <th>{__('Per 100g')}</th>
            <th>{__('Per serving')}</th>
          </tr>
        </thead>
        <tbody>
          {nutritions.map((el, i) => {
            return (
              <tr key={i}>
                <td>{el.item}</td>
                <td>{el.amountPer100}</td>
                <td>{el.amountPerServing}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  render() {
    const { __, profile, hideIncrementer, item, nutritionData } = this.props;
    const {
      quantity,
      price,
      showValidationAlert,
      allergensCodes,
      excludeTaxes,
      orderType,
    } = this.state;
    const menuDealGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const isAddToOrderBtnValid = validateItem(this.constructBasketItem()).errorCount === 0;
    const itemSku = item.sku && item.sku.split('-#')[0];
    const nutritionalInfo = nutritionData.find((el) => el.sku === itemSku) || null;
    let productPrice = item.productPrice;
    if (excludeTaxes) {
      productPrice = parseFloat(getItemTax(item, orderType)?.calculatedTaxPrice || 0);
    }
    return (
      <>
        <div className="item-details-card-content">
          <IonList className="item-details-card-list">
            <NormalText
              className="item-details-card-description"
              dangerouslySetInnerHTML={{
                __html: getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, ''),
              }}
            ></NormalText>
            <div className="item-details-card-price okx-font-secondary">
              {`${formatPrice(productPrice)} `}
              <StrongText>{__(`${excludeTaxes ? __('Exc VAT') : ''}`)}</StrongText>
            </div>
            {allergensCodes.length > 0 ? (
              <>
                <StrongText>{__('Allergens')}</StrongText>
                <IonItem lines="none">
                  <div tabIndex="-1"></div>
                  <PillGroup items={allergensCodes} borderColor="primary" />
                </IonItem>
              </>
            ) : null}

            {nutritionalInfo && this.drawNutritionalInfo(__, nutritionalInfo)}
            {menuDealGroups.map(this.drawMenuDealGroups)}
            {/* <IonItem lines="none">
							<Sectiontitle><strong>{ __('Special Instructions')}</strong> <span className="normal-text">{ __('Optional')}</span></Sectiontitle>
						</IonItem>
						<IonItem>
							<IonTextarea onIonChange={ this.instructionsChange } rows={ 1 } placeholder={ __('E.g No onions, etc')}></IonTextarea>
						</IonItem> */}
            {/* {getConfig().flags &&
            getConfig().flags.specialInstructions &&
            getConfig().flags.specialInstructions.isDisplayed ? (
              <>
                <IonItem lines="none">
                  <div tabIndex="-1"></div>
                  <Sectiontitle>
                    {__('Special Instructions')}{' '}
                    <span className="normal-text">{__('Optional')}</span>
                  </Sectiontitle>
                </IonItem>
                <IonItem>
                  <IonTextarea
                    onIonChange={this.instructionsChange}
                    rows={1}
                    placeholder={__(getConfig().flags.specialInstructions.placeholder)}
                  ></IonTextarea>
                </IonItem>
              </>
            ) : null} */}
          </IonList>
          {hideIncrementer ? null : (
            <div className="item-details-actions flex-col-wrapper flex-align-center flex-justify-between">
              <Incrementer
                allowNegative={false}
                quantity={quantity}
                onUpdate={this.onIncrementerUpdate}
              />
              <FlexSpacer size={20} />
              <IonButton
                disabled={quantity === 0 || !isAddToOrderBtnValid}
                className="item-details-add-to-order"
                size="full"
                shape="round"
                onClick={this.addToOrder}
              >
                {Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
                  ? __('Start New Order')
                  : __('Add to Order')}
              </IonButton>
            </div>
          )}
        </div>
        <IonAlert
          isOpen={showValidationAlert}
          onDidDismiss={() => this.setShowValidationAlert(false)}
          header={__('Validation')}
          message={__('Please check any required options')}
          buttons={[{ text: __('OK'), role: 'cancel', cssClass: 'secondary' }]}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    restaurantsUpdated: store.restaurants.restaurantsUpdated,
    basketUpdated: store.orders.basketUpdated,
    allergens: store.restaurants.allergens,
    nutritionData: store.restaurants.nutrition_data || [],
    storedItemWeb: store.orders.storedItemWeb,
    ikentooMenu: store.restaurants.ikentooMenu || {},
  };
};

export const ItemDetailsRaw = connect(mapStateToProps)(withTranslation(itemDetailsContent));

class itemDetails extends Component {
  constructor(props) {
    super(props);
    this.x = React.createRef();
    this.state = {
      nameVisible: true,
    };
  }
  onContentScrollHandler() {
    if (!this.isScrolledIntoView(this.x.current)) {
      this.setState({ nameVisible: false });
    } else {
      this.setState({ nameVisible: true });
    }
  }
  isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    return isVisible;
  }
  shouldComponentUpdate(prevProps, prevState) {
    if (this.state.nameVisible !== prevState.nameVisible) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    const { history, location, __, profile } = this.props;
    const item = location.state;
    const image =
      item.itemRichData && item.itemRichData.squareImageUrl ? item.itemRichData.squareImageUrl : '';

    const { selectedMenu } = item;

    return (
      <Layout
        headerTitle={__('Item Details')}
        scrollY={false}
        noPadding
        contentClassName="item-details-wrapper"
        hideSecondToolbar={true}
      >
        <div className="item-details-main-wrapper">
          <div
            className={
              this.state.nameVisible ? ' item-details-header' : 'active item-details-header'
            }
          >
            <div className="item-details-back">
              <IonButton
                className="solo-button"
                color="white"
                onClick={() => forwardTo('/order', { selectedMenu })}
              >
                <IonIcon slot="icon-only" icon={arrowBack} />
              </IonButton>
            </div>
            <div
              style={{
                transition: !this.state.nameVisible ? 'transform 0.3s ease-in-out' : 'none',
                display: 'flex',
                alignItems: 'center',
                transform: this.state.nameVisible ? 'translateY(-250%)' : 'translateY(0)',
              }}
            >
              <NormalText style={{ fontSize: '18px' }}>
                <strong> {__(getProductName(item, profile))}</strong>
              </NormalText>
            </div>
          </div>
          <div
            className="scrollable-y item-detals-background"
            onScroll={() => {
              this.onContentScrollHandler();
            }}
          >
            <div className="item-details-info">
              {image && image !== '' ? (
                <div className="item-details-img">
                  <img src={image} />
                </div>
              ) : (
                <div className="image-placeholder"></div>
              )}
              <div className="item-details-name" ref={this.x}>
                <Title> {__(getProductName(item, profile))}</Title>
              </div>
            </div>

            <ItemDetailsRaw item={item} profile={profile} />
          </div>
        </div>
        {/* {image && image !== '' ? (
          <div className="item-details-image" style={{ backgroundImage: 'url(' + image + ')' }} />
        ) : (
          noImage()
        )} */}

        {/* <Pullup
          className="item-details-card"
          offsetTop={55}
          offsetBottom={
            window && window.innerHeight ? Math.round(window.innerHeight / 2) + 20 : 220
          }
          contentOffsetBottom={120}
          header={
            <>
              <div className="item-details-card-header flex-col-wrapper flex-align-center">
                <div>
                  <Title>
                    <strong>{__(getProductName(item, profile))}</strong>
                  </Title>
                </div>
                <FlexSpacer size={20} />
                <div className="item-details-card-price">{formatPrice(item.productPrice)}</div>
              </div>
              <Hr thickness="5px" color="light" className="no-margin" />
            </>
          }
        >
          <ItemDetailsRaw item={item} profile={profile} />
        </Pullup> */}

        {/* <div className="item-details-back">
          <IonButton className="solo-button" color="white" onClick={() => history.goBack()}>
            <IonIcon slot="icon-only" icon={arrowBack} />
          </IonButton>
        </div> */}
      </Layout>
    );
  }
}

const stateToProps = (store) => {
  return {
    profile: store.profile.profile,
  };
};

export default connect(stateToProps)(withTranslation(itemDetails));
