import React from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonRadioGroup,
  IonRadio,
  IonAlert,
  IonIcon,
} from '@ionic/react';
import Layout from '../../components/layout';
import { Title, SmallText, Spacer, NormalText } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import moment from '../../lib/moment';
import {
  forwardToDeliveryOption,
  isEmptyObject,
  isObject,
  isArray,
  cutoffTime,
  groupBy,
  forwardTo,
  isDefined,
  deepCopy,
  parseAllergenData,
  getDisabledDatesForDeliveryOption,
  isWebConfig,
} from '../../lib/utils';
import { SET_COMMON_MODAL, SET_RESTAURANT_PROP } from '../../store/constants';
import { loading } from '../../store/actions';
import { setCommonModal, showToast } from '../../store/common/actions';
import { getIkentooMenu, getIkentooMenusForLocation } from '../../store/restaurants/actions';
import Basket from '../../lib/basket';
import Loading from '../../components/spinner';
import NoData from '../../components/noData';
import './index.css';
import mobiscroll from '@mobiscroll/react';
import { getConfig } from '../../appConfig';
import api from '../../lib/api';
import { close } from 'ionicons/icons';
import 'moment-timezone';

class DeliveryTime extends React.Component {
  pickerRef = React.createRef();
  state = {
    selectedTime: null,
    showValidationAlert: false,
  };
  checkDelivery = () => {
    if (!Basket.getDeliveryOption() || (!Basket.getDeliveryAddress() && !Basket.getPickUpPoint())) {
      forwardToDeliveryOption();
    }
  };
  componentDidMount() {
    this.checkDelivery();
    this.props.dispatch(
      getIkentooMenusForLocation(
        Basket.getRestaurant() ? Basket.getRestaurant().business_location_id : null,
        {},
        true,
      ),
    );
  }
  componentDidUpdate(prevProps) {
    if (this.props.deliveryOption !== prevProps.deliveryOption) {
      this.checkDelivery();
    }
    if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
      if (this.props.ikentooMenusForLocation[0]) {
        this.setState(
          { selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId },
          () => Basket.setMenu(this.props.ikentooMenusForLocation[0].ikentooMenuId),
        );
      }
    }
  }
  confirmTime = () => {
    const { selectedTime } = this.state;
    if (selectedTime) {
      // this.props.dispatch(setDeliveryTime(selectedTime));
      Basket.setCollectionTime(selectedTime.formattedDT);
      let restaurant = Basket.getRestaurant();
      let slots = restaurant?.delivery_times_json?.slots;
      let cutoffTimeRes = cutoffTime(selectedTime.formattedDT, slots);
      Basket.setCutoffTime(cutoffTimeRes);
      this.continueOnMenu();
    }
  };

  changeIkentooMenus = (event) =>
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () =>
      Basket.setMenu(event.detail.value),
    );

  formatDayName = (name, key) => {
    if (name.includes('Today')) {
      name = 'Today';
    } else if (name.includes('Tomorrow')) {
      name = 'Tomorrow';
    } else {
      name = key;
    }
    return name;
  };

  formatCharterDeliveryTimes = (timesJson, restaurant) => {
    const {
      general: { charterDeliveryAheadDays },
    } = getConfig();
    const { charter_delivery_order_slot_interval_mins } = restaurant;
    const storeTimes = [];
    const currentDayOfTheWeek = moment().day();
    for (let charterMenu of timesJson) {
      Array.from({ length: charterDeliveryAheadDays }).forEach((_, currentDayAhead) => {
        const dateTimeEntry =
          charterMenu.availability[(currentDayAhead + currentDayOfTheWeek - 1) % 7];
        const [startHours, startMinutes] = dateTimeEntry.start.split(':');
        const [endHours, endMinutes] = dateTimeEntry.end.split(':');
        const startDate = moment()
          .add(currentDayAhead, 'days')
          .set('hours', startHours)
          .set('minutes', startMinutes);
        const endDate = moment()
          .add(currentDayAhead, 'days')
          .set('hours', endHours)
          .set('minutes', endMinutes);
        const differenceInMinutes = moment.duration(endDate.diff(startDate)).asMinutes();
        if (differenceInMinutes > 0) {
          for (
            let minutesExtended = 0;
            minutesExtended <= differenceInMinutes;
            minutesExtended = minutesExtended + charter_delivery_order_slot_interval_mins
          ) {
            const newDate = moment(startDate).add(minutesExtended, 'minutes');
            storeTimes.push({
              formattedDT: newDate,
              value: newDate.toDate(),
              date: newDate.format('ddd, DD MMM'),
              time: newDate.format('h:mm A'),
              menuIds: [charterMenu.menuId],
            });
          }
        }
      });
    }
    return this.formatTimesForTree(storeTimes);
  };

  formatTimes = ({ orderType, restaurant }) => {
    let deliveryTimesJson;
    const disabledDates = getDisabledDatesForDeliveryOption(getConfig()?.disabled_dates, orderType);
    if (!restaurant) {
      deliveryTimesJson = null;
    } else {
      deliveryTimesJson =
        orderType === 'charter-delivery'
          ? restaurant.charter_delivery_times_json
          : restaurant.delivery_times_json;
    }
    if (orderType === 'charter-delivery') {
      return this.formatCharterDeliveryTimes(deliveryTimesJson, restaurant);
    }
    const formatSelectOptionDays = [];
    const daysAhead = deliveryTimesJson ? deliveryTimesJson.days_ahead : null;
    let picker = [];
    if (deliveryTimesJson) {
      Array(daysAhead)
        .fill('')
        .forEach((day, i) => {
          let formatDay = moment().tz('Europe/London').add(i, 'days');
          let formatDayName = formatDay.format('dddd');
          formatSelectOptionDays.push({ formatDayName, formattedDT: formatDay });
        });
      formatSelectOptionDays.forEach((option, i) => {
        const formatedOptionDate = option.formattedDT.format('DD-MM-YYYY');
        let daySlot = [];
        if (isDefined(disabledDates) && disabledDates.includes(formatedOptionDate)) {
          daySlot = [];
        } else {
          daySlot = deliveryTimesJson.slots[option.formatDayName];
        }
        daySlot.forEach((slot) => {
          let h = parseInt(slot.start_time.split(':')[0]);
          let m = parseInt(slot.start_time.split(':')[1]);
          let prepTime = moment()
            .tz('Europe/London')
            .add(slot.prep_time, 'hours')
            .add(m, 'minutes');
          const formattedDT = moment(option.formattedDT.hours(h).minutes(m)).tz('Europe/London');
          if (prepTime.isBefore(formattedDT)) {
            picker.push({
              formattedDT: formattedDT,
              value: formattedDT.toDate(),
              date: formattedDT.format('ddd, DD MMM'),
              time: formattedDT.format('h:mm A'),
              menuIds: slot.menu_ids || null,
            });
          }
        });
      });
      const formattedTreeDataSource = this.formatTimesForTree(picker);
      return formattedTreeDataSource;
    }
    return [];
  };

  formatTimesForTree = (times) => {
    const groupByMonth = groupBy(['date']);
    const grouped = groupByMonth(times);
    return grouped;
  };

  setShowValidationAlert = (flag) => this.setState({ showValidationAlert: flag });

  continueOnMenu = async () => {
    const { dispatch, __ } = this.props;
    dispatch(loading(true));
    const { selectedIkentooMenu, selectedTime } = this.state;
    const { ikentooMenusForLocation } = this.props;
    if (Basket.getRestaurant() && selectedIkentooMenu) {
      const choosenRestaurant = Basket.getRestaurant();
      const businessLocationId = choosenRestaurant.business_location_id;
      Basket.setCollectionTime(selectedTime.formattedDT);
      const menus = this.getMenusForDeliverySelectedTime(ikentooMenusForLocation, selectedTime);
      if (menus.length == 0) {
        dispatch(showToast(__('Get restaurant menu error.'), 'warning'));
      } else if (menus.length === 1) {
        Basket.setMenu(menus[0].menu_id);
        if (this.props.location?.state?.selectedRestaurant?.items.length > 0) {
          let ikentooMenu = {};
          try {
            ikentooMenu = await api.getIkenooMenu(menus[0].menu_id, businessLocationId);
          } catch (error) {
            dispatch(showToast(__('Get restaurant menu error.'), 'warning'));
          }
          if (!isEmptyObject(ikentooMenu)) {
            dispatch({
              type: SET_RESTAURANT_PROP,
              key: 'ikentooMenu',
              value: ikentooMenu,
            });
            const categoryItems = ikentooMenu.menuEntry || ikentooMenu.menuEntryGroups || null;
            const items = Basket.flattenMenuItems(deepCopy(categoryItems));
            if (items.length > 0) {
              let validationStatus = { notBasketEmpty: false, validationError: false };
              this.props.location.state.selectedRestaurant.items.map((newBasketItem, index) => {
                let foundItem = items.find((i) => i.sku === newBasketItem.item.sku);
                if (foundItem && Basket.isProductEnabled(foundItem)) {
                  for (let i = 0; i < newBasketItem.selectedChoices.length; i++) {
                    for (let j = 0; j < newBasketItem.selectedChoices[i].length; j++) {
                      if (Basket.isProductEnabled(newBasketItem.selectedChoices[i][j])) {
                        let modifierSku = newBasketItem.selectedChoices[i][j].sku;
                        const foundModifier = foundItem.menuDealGroups[i].items.find(
                          (el) => el.sku == modifierSku,
                        );
                        newBasketItem.selectedChoices[i][j] = foundModifier;
                      } else {
                        newBasketItem.selectedChoices[i][j] = false;
                      }
                    }
                  }
                  newBasketItem.selectedChoices = newBasketItem.selectedChoices.map((el) => {
                    return el.filter(Boolean);
                  });
                  newBasketItem.item = foundItem;
                  Basket.addToBasket(newBasketItem);
                  let item = newBasketItem.item;
                  let allergens = this.props.allergens;
                  let profile = this.props.profile;
                  let newArr = parseAllergenData(profile, item, allergens);
                  let allergensCodes =
                    newBasketItem?.item?.itemRichData?.allergenCodes?.length > 0
                      ? newBasketItem.item.itemRichData.allergenCodes
                      : [];
                  if (allergensCodes.length > 0) {
                    let allergensData = [{ allergens: newArr }, { sku: newBasketItem.item.sku }];
                    Basket.setAllergen(allergensData);
                  }
                  validationStatus.notBasketEmpty = true;
                } else {
                  validationStatus.validationError = true;
                }
              });
              if (validationStatus.notBasketEmpty) {
                if (validationStatus.validationError) {
                  dispatch(
                    showToast(
                      __(
                        'Some items were not added to your basket as they are currently unavailable',
                      ),
                      'warning',
                    ),
                  );
                }
                forwardTo('/order-summary', { skipBackToThePreviousPage: false });
              } else {
                this.setShowValidationAlert(true);
              }
            }
          }
        } else {
          dispatch(getIkentooMenu(menus[0].menu_id, businessLocationId));
        }
      } else {
        dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId));
      }
    } else {
      dispatch(showToast('Please select location menu', 'warning'));
      this.setState({ error: 'Please select location menu' });
    }
    dispatch(loading(false));
  };
  getMenusForDeliverySelectedTime = (menusForLocation = [], selectedTime) => {
    if (isObject(menusForLocation) && !isArray(menusForLocation)) {
      menusForLocation = [menusForLocation];
    }
    return menusForLocation.filter((menu) => {
      return selectedTime?.menuIds.includes(menu.ikentooMenuId);
    });
  };

  setTime = (e, deliverytimes, isPicker) => {
    const findPickedDT = isPicker ? deliverytimes.find((dt) => e.valueText === dt.text) : e;
    this.setState({ selectedTime: findPickedDT }, () => {
      let restaurant = Basket.getRestaurant();
      let slots = restaurant?.delivery_times_json?.slots;
      let cutoffTimeRes = cutoffTime(findPickedDT.formattedDT, slots);
      Basket.setCutoffTime(cutoffTimeRes);
      if (findPickedDT.menuIds.length > 1) {
        this.props.dispatch(setCommonModal('isChooseMenuModalOpen', true));
      } else if (findPickedDT.menuIds.length === 1) {
        this.setState({ selectedIkentooMenu: findPickedDT.menuIds[0] }, () => {
          if (isPicker) {
            this.continueOnMenu();
          }
        });
      }
    });
  };

  parseSelectedTime(dataSource, selected) {
    const [selectedDateIndex, selectedTimeIndex] = selected.split(' ');
    const selectedDate = Object.keys(dataSource)[selectedDateIndex];
    const selectedTime = dataSource[selectedDate][selectedTimeIndex];
    this.setState({ selectedTime: selectedTime });
  }

  render() {
    const { __, deliveryOption, isChooseMenuModalOpen, ikentooMenusForLocation } = this.props;
    const { selectedTime, selectedIkentooMenu } = this.state;
    const restaurant = Basket.getRestaurant();
    const orderType = Basket.getOrderType();
    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
    const menus = this.getMenusForDeliverySelectedTime(ikentooMenusForLocation, selectedTime);
    const timePickerTimes = this.formatTimes({ restaurant, orderType });
    return (
      <Layout hideSecondToolbar noPadding>
        <Loading>
          <div className="flex-row-wrapper absolute-content">
            <div className="scrollable-y">
              <Title>
                {__(
                  deliveryOption && deliveryOption.id === 'delivery'
                    ? 'Select Delivery Time'
                    : 'Select Drop-off Time',
                )}
              </Title>
              {Object.keys(timePickerTimes).length > 0 ? (
                <>
                  <NormalText>
                    {__(
                      deliveryOption && deliveryOption.id === 'delivery'
                        ? "Select the time you'd like your order to be delivered"
                        : 'Select your prefered drop-off time',
                    )}
                  </NormalText>
                  <Spacer />
                  <mobiscroll.Treelist
                    display="inline"
                    layout="liquid"
                    showInput={false}
                    circular={false}
                    onChange={(e) => this.parseSelectedTime(timePickerTimes, e.valueText)}
                    onInit={() => this.parseSelectedTime(timePickerTimes, '0 0')}
                  >
                    {Object.keys(timePickerTimes).map((date, i) => (
                      <li key={i}>
                        {date}
                        <ul>
                          {timePickerTimes[date].map((time, j) => (
                            <li key={j}>{time.time}</li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </mobiscroll.Treelist>
                </>
              ) : null}
            </div>
            <div className="flex-min">
              <IonButton
                disabled={!selectedTime}
                expand="block"
                color="primary"
                onClick={() =>
                  menus.length > 1
                    ? this.props.dispatch({
                        type: SET_COMMON_MODAL,
                        modal: 'isChooseMenuModalOpen',
                        value: true,
                      })
                    : this.confirmTime()
                }
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
          <div
            className="click-collect-pickers-backdrop"
            style={{ display: isChooseMenuModalOpen ? '' : 'none' }}
            onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
          ></div>
          <div className={`click-collect-dialog ${animationMenuClass}`}>
            <Loading> {null} </Loading>
            <div className="click-collect-dialog-layout sc-ion-modal-md">
              <div className="click-collect-dialog-header">
                <h3>{__('Choose menu')}</h3>
              </div>
              <div
                className="click-collect-dialog-closer"
                style={{ position: 'absolute', right: 0, top: 0 }}
                onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
              >
                <IonIcon icon={close} class="md hydrated"></IonIcon>
              </div>
              <div className="click-collect-dialog-content">
                <IonList lines="full">
                  <IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
                    {menus.length === 0 ? (
                      <NoData />
                    ) : (
                      menus.map((menu) => {
                        const { ikentooMenuId, menuName } = menu;
                        return (
                          <IonItem key={ikentooMenuId}>
                            <div tabIndex="-1"></div>
                            <IonRadio
                              color={'secondary'}
                              slot="start"
                              value={ikentooMenuId}
                            />
                            <IonLabel className="ion-text-wrap" color="dark">
                              {menuName}
                            </IonLabel>
                          </IonItem>
                        );
                      })
                    )}
                  </IonRadioGroup>
                </IonList>
              </div>
              <div className="click-collect-dialog-action">
                <IonButton
                  disabled={menus.length > 0 ? false : true}
                  expand="block"
                  color="primary"
                  onClick={() => this.confirmTime()}
                >
                  {__('Next')}
                </IonButton>
              </div>
            </div>
          </div>
          <IonAlert
            isOpen={this.state.showValidationAlert}
            onDidDismiss={() => this.setShowValidationAlert(false)}
            header={__('Error')}
            message={__('Some items were unavailable at this time')}
            buttons={[
              {
                text: __('Start new order'),
                cssClass: 'secondary',
                handler: () => forwardTo('/order'),
              },
            ]}
          />
        </Loading>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { deliveryOption, deliveryAddress, pickUpPoint } = state.orders;
  const { isChooseMenuModalOpen } = state.common;
  const { ikentooMenusForLocation, restaurants } = state.restaurants;
  return {
    deliveryOption,
    deliveryAddress,
    pickUpPoint,
    isChooseMenuModalOpen,
    ikentooMenusForLocation,
    restaurants,
  };
};

export default connect(stateToProps)(withTranslation(DeliveryTime));
