import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonItem,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
  IonAlert,
  IonCheckbox,
  IonIcon,
} from '@ionic/react';
import api from '../../lib/api';
import { SET_RESTAURANT_PROP } from '../../store/constants';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import {
  Title,
  FieldError,
  SmallText,
  Spacer,
  NormalText,
  StrongText,
} from '../../components/common';
import NoData from '../../components/noData';
import Mobiscroll from '../../components/mobiscroll';
import mobiscroll from '@mobiscroll/react';

import {
  setCommonModal,
  getRestaurants,
  getIkentooMenu,
  getIkentooMenusForLocation,
  setDeliveryOption,
  setSelectedSubscription,
  showToast,
  loading,
} from '../../store/actions';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import {
  isDefined,
  checkForDeliveryOption,
  isObject,
  isArray,
  forwardTo,
  deepCopy,
  parseAllergenData,
  groupBy,
  getDisabledDatesForDeliveryOption,
} from '../../lib/utils';
import moment from '../../lib/moment';
import { getConfig } from '../../appConfig';
import { close } from 'ionicons/icons';
import 'moment-timezone';
import './index.css';

const { SelectOption } = Mobiscroll;
const restaurantSelectPlaceholder = 'Select collection location';
const ASAP = 'ASAP';
// const customTime = '2020-07-13T23:50:10.546'

const createMomentFromTime = (time = '') => {
  const parsedTime = time.split(':');
  if (parsedTime.length !== 2 || time === '') {
    return null;
  }
  let hour = parseInt(time.split(':')[0]);
  let minutes = parseInt(time.split(':')[1]);
  return moment().hours(hour).minutes(minutes).tz('Europe/London');
};

const toWhichSegmentTimeBelongs = (time, segments = []) => {
  let timeIsInSegment = -1;
  segments.forEach((segment, index) => {
    const { start, end } = segment;
    const targetTime = createMomentFromTime(time);
    const segmentStart = createMomentFromTime(start);
    // Adding extra seconds because of bug on Native devices
    segmentStart.subtract(5, 'seconds');
    const segmentEnd = createMomentFromTime(end);
    segmentEnd.add(5, 'seconds');
    if (targetTime.isSameOrAfter(segmentStart) && targetTime.isSameOrBefore(segmentEnd)) {
      timeIsInSegment = index;
    }
  });
  return timeIsInSegment;
};

const isTimeInSegment = (time, segments = []) => {
  return toWhichSegmentTimeBelongs(time, segments) !== -1;
};

const addSegment = (start, end, segments = [], date) => {
  let updatedSegments = [...segments];
  const dayNumber = 'w' + date.day();
  const newSegment = { d: dayNumber, start: start, end: end };

  // check previously added segment. maybe some of them are handled with new added segment
  const oldSegments = [...segments];
  oldSegments.forEach((oldSegment, index) => {
    if (
      isTimeInSegment(oldSegment.start, [newSegment]) &&
      isTimeInSegment(oldSegment.end, [newSegment])
    ) {
      updatedSegments = removeSegment(index, updatedSegments);
    }
  });
  return [...updatedSegments, newSegment];
};

const updateSegment = (segmentIndex, propName, value, segments = []) => {
  let updatedSegments = [...segments];
  if (updatedSegments && updatedSegments[segmentIndex]) {
    updatedSegments[segmentIndex][propName] = value;
  }
  return updatedSegments;
};

const removeSegment = (segmentIndexForRemove, segments = []) =>
  [...segments]
    .map((segment, index) => (index === segmentIndexForRemove ? null : segment))
    .filter((segment) => isDefined(segment));

export const parseTimesJson = (json = [], date) => {
  let parsed_json = [];
  const dayNumber = 'w' + date.day();

  // use old logic for 'json_time_selector' json (without: menuId and availabity)
  if (json.length > 0 && !json[0].menuId) {
    return json;
  }
  json.forEach((menu) => {
    (menu.availability || [])
      .filter((i) => i.d === dayNumber)
      .forEach((time) => {
        const { start, end } = time;
        if (parsed_json.length === 0) {
          // add first available time
          parsed_json = addSegment(start, end, parsed_json, date);
        } else {
          if (!isTimeInSegment(start, parsed_json) && !isTimeInSegment(end, parsed_json)) {
            // case 1: start and end dont belong to any other segment
            parsed_json = addSegment(start, end, parsed_json, date);
          } else if (isTimeInSegment(start, parsed_json) && !isTimeInSegment(end, parsed_json)) {
            // case 2: start belong to some segment and end dont bolong to any segment
            const segmentIndex = toWhichSegmentTimeBelongs(start, parsed_json);
            parsed_json = updateSegment(segmentIndex, 'end', end, parsed_json);
          } else if (!isTimeInSegment(start, parsed_json) && isTimeInSegment(end, parsed_json)) {
            // case 3: end belong to some segment and start dont bolong to any segment
            const segmentIndex = toWhichSegmentTimeBelongs(end, parsed_json);
            parsed_json = updateSegment(segmentIndex, 'start', start, parsed_json);
          } else if (isTimeInSegment(start, parsed_json) && isTimeInSegment(end, parsed_json)) {
            // case 4: and start and end belongs to some segment
            const startSegmentIndex = toWhichSegmentTimeBelongs(start, parsed_json);
            const endSegmentIndex = toWhichSegmentTimeBelongs(end, parsed_json);
            if (parsed_json && parsed_json[startSegmentIndex] && parsed_json[endSegmentIndex]) {
              const newStartTime = parsed_json[startSegmentIndex].start;
              const newEndTime = parsed_json[endSegmentIndex].end;

              if (startSegmentIndex !== endSegmentIndex) {
                parsed_json = addSegment(newStartTime, newEndTime, parsed_json, date);
                parsed_json = removeSegment(startSegmentIndex, parsed_json);
                parsed_json = removeSegment(endSegmentIndex, parsed_json);
              }
            }
          }
        }
      });
  });

  // sort times by 'start' time
  return parsed_json.sort((a, b) => {
    const aStart = createMomentFromTime(a.start);
    const bStart = createMomentFromTime(b.start);
    return bStart.isSameOrBefore(aStart) ? 1 : -1;
  });
};

/*
menusForLocation: [{menuName: "Winter Menu 2014", ikentooMenuId: 37082747671397}, ...]
pickTime: 12:45
json_time_selector: [{
  "menuId": 37082747671609,
  "availability": [
    { "d": "w1", "start": "07:15", "end": "15:45" },
    { "d": "w2", "start": "07:15", "end": "15:45" },
    { "d": "w3", "start": "07:15", "end": "15:45" },
    { "d": "w4", "start": "07:15", "end": "15:45" },
    { "d": "w5", "start": "07:15", "end": "15:45" }
  ]
}, ... ]
*/
export const getMenusForSelectedTime = (
  menusForLocation = [],
  pickTime,
  json_time_selector = [],
  day,
) => {
  if (isObject(menusForLocation) && !isArray(menusForLocation)) {
    menusForLocation = [menusForLocation];
  }
  return menusForLocation.filter((menu) => {
    const ikentooMenuId = menu.ikentooMenuId;

    if (json_time_selector.length > 0 && json_time_selector[0].menuId && pickTime) {
      const target_menu = json_time_selector.find((i) => i.menuId === ikentooMenuId);
      let dayNumber = day;
      if (isNaN(parseInt(dayNumber))) {
        dayNumber = moment().tz('Europe/London').day();
      }
      if (
        target_menu &&
        isTimeInSegment(
          pickTime,
          target_menu.availability.filter((i) => i.d === `w${dayNumber}`),
        )
      ) {
        return true;
      }
    } else {
      // handle old json_time_selector (without menuId and availability)
      return false;
    }

    return false;
  });
};

class ClickAndCollect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRestaurant: props.location?.state?.selectedRestaurant
        ? props.location.state.selectedRestaurant.restaurant_id
        : null,
      pickTime: null,
      pickDay: null,
      dayOfTheWeek: null,
      error: '',
      showValidationAlert: false,
      asapTime: null,
      showTime: false,
    };
    this.timeSlotRef = React.createRef();
    this.timePickerOptions = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.pickTime !== this.state.pickTime ||
      nextProps.restaurants !== this.props.restaurants ||
      nextState.selectedRestaurant !== this.state.selectedRestaurant ||
      nextProps.ikentooMenusForLocation !== this.props.ikentooMenusForLocation ||
      nextProps.isChooseMenuModalOpen !== this.props.isChooseMenuModalOpen ||
      nextState.showValidationAlert !== this.state.showValidationAlert ||
      nextState.error !== this.state.error ||
      nextState.showTime !== this.state.showTime
    ) {
      return true;
    } else {
      return false;
    }
  }
  componentDidMount() {
    Basket.setOrderType('collection');
    const deliveryOption = checkForDeliveryOption(Basket.getDeliveryOption(), '/click-and-collect');
    if (deliveryOption) {
      this.props.dispatch(getRestaurants());
      this.props.dispatch(setDeliveryOption(deliveryOption));
    }
    if (Basket.getMenu()) {
      this.setState({ selectedIkentooMenu: Basket.getMenu() });
    }
    // this.position();

    if (this.props.active_subscription) {
      const index = this.props.subscriptions.findIndex(
        (e) => e.id === this.props.active_subscription.id,
      );
      this.props.setSelectedSubscription({ id: this.props.active_subscription.id, idx: index });
    }

    const stores = this.props.restaurants || [];
    if (this.formatDataForSelect(stores).length > 0 && this.state.selectedRestaurant) {
      const is_existing_location = this.formatDataForSelect(stores).filter(
        (i) => i.value === this.state.selectedRestaurant,
      ).length;
      if (is_existing_location) {
        this.selectRestaurant();
      }
    }
  }
  selectRestaurant = (selectedRestaurantId) => {
    const { restaurants, profile } = this.props;
    this.setState(
      { selectedRestaurant: selectedRestaurantId, pickTime: null, showTime: true },
      () => {
        Basket.reset(profile.cardToken);
        Basket.setRestaurant(
          restaurants.find((restaurant) => restaurant.id === selectedRestaurantId),
        );

        Basket.setCollectionTime(null);
        // Basket.setProcessingFee();
        Basket.setOrderType('collection');
      },
    );
  };
  // selectRestaurant = (event, data) => {
  //   const { restaurants, profile } = this.props;
  //   if (data) {
  //     const selectedRestaurantId = data.getVal();
  //     this.setState({ selectedRestaurant: selectedRestaurantId, pickTime: null }, () => {
  //       if (!this.props.location.state) Basket.reset(profile.cardToken);
  //       Basket.setRestaurant(
  //         restaurants.find((restaurant) => restaurant.id === selectedRestaurantId),
  //       );
  //       Basket.setCollectionTime(null);
  //       Basket.setOrderType('collection');
  //       this.refreshTimeSlot();
  //     });
  //   } else {
  //     this.setState({ pickTime: null }, () => {
  //       if (!this.props.location.state) Basket.reset(profile.cardToken);
  //       Basket.setRestaurant(
  //         restaurants.find((restaurant) => restaurant.id === this.state.selectedRestaurantId),
  //       );
  //       Basket.setCollectionTime(null);
  //       Basket.setOrderType('collection');
  //     });
  //   }
  // };

  formatTime = (selectedTime, date) => {
    let h = parseInt(selectedTime.split(':')[0]);
    let m = parseInt(selectedTime.split(':')[1]);
    return moment(date).hours(h).minutes(m).tz('Europe/London');
  };

  parseSelectedTime(dataSource, selected) {
    const asap_active = getConfig().general.asap_active || false;
    let selectedTimeSlot =
      selected.match(/\d{1,2}:\d{2} (AM|PM)/)?.[0] || (asap_active ? 'ASAP' : 'CLOSED');
    if (asap_active) {
      selectedTimeSlot = selected.indexOf(selectedTimeSlot) !== -1 ? selectedTimeSlot : 'CLOSED';
    }
    const selectedDate = selected.replace(' ' + selectedTimeSlot, '');
    const selectedTime = dataSource[selectedDate].find(
      (option) => option.time === selectedTimeSlot,
    );
    this.setState(
      {
        dayOfTheWeek: moment(selectedTime.formattedDT).tz('Europe/London').day(),
        pickTime: selectedTime.slot,
        pickDay: moment(selectedTime.formattedDT).tz('Europe/London').format('D'),
      },
      () => {
        Basket.setCollectionTime(selectedTime.formattedDT);
        Basket.setOrderType('collection');
        Basket.setAsapFlag(selectedTime.time === ASAP ? true : false);
      },
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.deliveryOption !== this.props.deliveryOption) {
      checkForDeliveryOption(
        this.props.deliveryOption ? this.props.deliveryOption : Basket.getDeliveryOption(),
        '/click-and-collect',
      );
    }
    if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
      if (this.props.ikentooMenusForLocation[0]) {
        this.setState({ selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId });
      }
    }
  }

  continueOnMenu = () => {
    const { restaurants, dispatch } = this.props;
    const { selectedIkentooMenu, selectedRestaurant } = this.state;
    if (selectedRestaurant && selectedIkentooMenu) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant,
      );
      const businessLocationId = choosenRestaurant.business_location_id;
      dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId));
    } else {
      this.setState({ error: 'Please select location menu' });
    }
  };

  chooseMenusForLocation = async () => {
    Basket.setOrderType('collection');
    const { restaurants, dispatch, __ } = this.props;
    dispatch(loading(true));
    const { selectedRestaurant, pickTime, asapTime } = this.state;
    let collection_time = null;
    if (isDefined(Basket.collection_time)) {
      collection_time = moment().tz('Europe/London').unix(Basket.collection_time);
    }
    let currentDT = moment().tz('Europe/London').unix();
    if (currentDT > collection_time) {
      dispatch(setCommonModal('isTimeCollectionModalOpen', true));
    } else {
      if (selectedRestaurant && pickTime) {
        const choosenRestaurant = restaurants.find(
          (restaurant) => restaurant.id === selectedRestaurant,
        );
        let cutoffTimeRes = moment().tz('Europe/London').unix();
        Basket.setCutoffTime(cutoffTimeRes);
        Basket.setRestaurant(choosenRestaurant);
        const businessLocationId = choosenRestaurant.business_location_id;
        if (
          this.props.location.state &&
          this.props.location.state.selectedRestaurant.items.length > 0
        ) {
          let ikentooMenusForLocation = await api.getIkentooMenusForLocation(businessLocationId);
          if (isDefined(ikentooMenusForLocation.error)) {
            ikentooMenusForLocation = [];
          }
          ikentooMenusForLocation = getMenusForSelectedTime(
            ikentooMenusForLocation,
            pickTime === ASAP ? asapTime : pickTime,
            choosenRestaurant.json_time_selector,
            this.state.dayOfTheWeek,
          );

          if (ikentooMenusForLocation && isObject(ikentooMenusForLocation[0])) {
            let ikentooMenu = {};
            try {
              ikentooMenu = await api.getIkenooMenu(
                ikentooMenusForLocation[0].menu_id,
                businessLocationId,
              );
              Basket.setMenu(ikentooMenusForLocation[0].menu_id);
            } catch (error) {
              dispatch(showToast(__('Get restaurant menu error.'), 'warning'));
            }
            dispatch({
              type: SET_RESTAURANT_PROP,
              key: 'ikentooMenu',
              value: ikentooMenu,
            });
            const categoryItems = ikentooMenu.menuEntry || ikentooMenu.menuEntryGroups || null;
            let items = Basket.flattenMenuItems(deepCopy(categoryItems));
            if (items.length > 0) {
              let validationStatus = { notBasketEmpty: false, validationError: false };
              this.props.location.state.selectedRestaurant.items.map((newBasketItem, index) => {
                let foundItem = items.find((i) => i.sku === newBasketItem.item.sku);
                if (foundItem && Basket.isProductEnabled(foundItem)) {
                  for (let i = 0; i < newBasketItem.selectedChoices.length; i++) {
                    for (let j = 0; j < newBasketItem.selectedChoices[i].length; j++) {
                      if (Basket.isProductEnabled(newBasketItem.selectedChoices[i][j])) {
                        let modifierSku = newBasketItem.selectedChoices[i][j].sku;
                        const foundModifier = foundItem.menuDealGroups[i].items.find(
                          (el) => el.sku == modifierSku,
                        );
                        newBasketItem.selectedChoices[i][j] = foundModifier;
                      } else {
                        newBasketItem.selectedChoices[i][j] = false;
                      }
                    }
                  }

                  newBasketItem.selectedChoices = newBasketItem.selectedChoices.map((el) => {
                    return el.filter(Boolean);
                  });

                  newBasketItem.item = foundItem;
                  Basket.addToBasket(newBasketItem);
                  let item = newBasketItem.item;
                  let allergens = this.props.allergens;
                  let profile = this.props.profile;
                  let newArr = parseAllergenData(profile, item, allergens);
                  let allergensCodes =
                    newBasketItem.item?.itemRichData?.allergenCodes?.length > 0
                      ? newBasketItem.item.itemRichData.allergenCodes
                      : [];
                  if (allergensCodes.length > 0) {
                    let allergensData = [{ allergens: newArr }, { sku: newBasketItem.item.sku }];
                    Basket.setAllergen(allergensData);
                  }
                  validationStatus.notBasketEmpty = true;
                } else {
                  validationStatus.validationError = true;
                }
              });
              if (validationStatus.notBasketEmpty) {
                if (validationStatus.validationError) {
                  dispatch(
                    showToast(
                      __(
                        'Some items were not added to your basket as they are currently unavailable',
                      ),
                      'warning',
                    ),
                  );
                }
                forwardTo('/order-summary', { skipBackToThePreviousPage: false });
              } else {
                this.setShowValidationAlert(true);
              }
            }
          }
        } else {
          dispatch(
            getIkentooMenusForLocation(businessLocationId, {
              pickTime: pickTime === ASAP ? asapTime : pickTime,
              json_time_selector: choosenRestaurant ? choosenRestaurant.json_time_selector : [],
              dayOfTheWeek: this.state.dayOfTheWeek,
            }),
          );
        }
      } else if (!selectedRestaurant) {
        this.setState({ error: 'Please select location' });
      } else {
        this.setState({ error: 'Please select collection time' });
      }
    }
    dispatch(loading(false));
  };

  setShowValidationAlert = (flag) => this.setState({ showValidationAlert: flag });

  onRemoveCollectionTimeModal = () => {
    const { dispatch } = this.props;
    dispatch(setCommonModal('isTimeCollectionModalOpen', false));
    this.setState({ pickTime: null });
  };

  changeIkentooMenus = (event) => {
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
      Basket.setMenu(event.detail.value);
    });
  };

  formatDataForSelect = (stores) => {
    const { __ } = this.props;
    let arrForSelect = [];
    stores.forEach((store) => {
      if (isDefined(store.can_collection_order) && isDefined(store.is_published)) {
        if (store.can_collection_order && store.is_published) {
          arrForSelect.push(store);
        }
      }
    });
    return arrForSelect;
  };

  formatDataForTime = (store, minDate) => {
    let timesInBetween = [];
    let storeTimes = [];
    const minDT = minDate.format('HH:mm');
    const date = moment(minDate).tz('Europe/London');
    const dow = 'w' + date.day();
    const period = store && store.order_slot_interval_mins ? store.order_slot_interval_mins : 5;
    const collection_minutes = [];
    let minutes = -period;

    while (minutes < 60) {
      minutes += period;
      if (minutes < 60) {
        collection_minutes.push(minutes);
      }
    }
    if (store) {
      parseTimesJson(store.json_time_selector, date).forEach((time) => {
        if (time.d === dow) {
          storeTimes.push(time);
        }
      });
    }

    function makePickerText(times, j, i) {
      let collectionMinutes = parseInt(times[j]) < 10 ? '0' + times[j] : times[j];
      return {
        text: i < 10 ? '0' + i + ':' + collectionMinutes : i + ':' + collectionMinutes,
        value: i < 10 ? '0' + i + ':' + collectionMinutes : i + ':' + collectionMinutes,
      };
    }

    function returnTimesInBetween(start, end, cnt) {
      let startH = parseInt(start.split(':')[0]);
      let startM = parseInt(start.split(':')[1]);
      let endH = parseInt(end.split(':')[0]);
      let endM = parseInt(end.split(':')[1]);
      let minTimeStart = parseInt(minDT.split(':')[0]);
      let minTimeEnd = parseInt(minDT.split(':')[1]);
      let c = collection_minutes.filter((cm) => cm >= startM);
      let cm = collection_minutes.filter((cm) => cm <= endM);
      let startHH = startH;
      if (startHH <= minTimeStart) {
        startHH = minTimeStart;
      }

      for (let i = startHH; i <= endH; i++) {
        if (startH === i) {
          for (let j = 0; j < c.length; j++) {
            if (c[j] >= minTimeEnd && cnt === 0 && startH <= minTimeStart) {
              timesInBetween.push(makePickerText(c, j, i));
            } else if (cnt !== 0) {
              timesInBetween.push(makePickerText(c, j, i));
            } else if (startH > minTimeStart) {
              timesInBetween.push(makePickerText(c, j, i));
            }
          }
        } else if (endH === i) {
          if (minTimeStart === i) {
            for (let j = 0; j < cm.length; j++) {
              if (cm[j] >= minTimeEnd) {
                timesInBetween.push(makePickerText(cm, j, i));
              }
            }
          } else {
            for (let j = 0; j < cm.length; j++) {
              timesInBetween.push(makePickerText(cm, j, i));
            }
          }
        } else {
          if (i === minTimeStart) {
            let collection_m = collection_minutes.filter((cm) => cm >= minTimeEnd);
            for (let j = 0; j < collection_m.length; j++) {
              timesInBetween.push(makePickerText(collection_m, j, i));
            }
          } else {
            for (let j = 0; j < collection_minutes.length; j++) {
              timesInBetween.push(makePickerText(collection_minutes, j, i));
            }
          }
        }
      }

      //if we have no oppning times, just add 'CLOSED' label to the picker
      if (
        timesInBetween &&
        timesInBetween.length > 1 &&
        timesInBetween[0] &&
        timesInBetween[0].text === 'CLOSED'
      ) {
        timesInBetween.shift();
      }
      //if we have no oppning times, just add 'CLOSED' label to the picker
      if (isDefined(store) && timesInBetween.length === 0) {
        timesInBetween.push({ text: 'CLOSED', value: null });
      }
      return timesInBetween;
    }
    storeTimes.forEach((storeT, i, arr) => {
      returnTimesInBetween(storeT.start, storeT.end, i);
      let minH = parseInt(minDT.split(':')[0]);
      let minM = parseInt(minDT.split(':')[1]);
      let endTimeH = parseInt(storeT.end.split(':')[0]);
      let endTimeM = parseInt(storeT.end.split(':')[1]);
      let minTime = date.hours(minH).minutes(minM);
      let timeEnd = date.hours(endTimeH).minutes(endTimeM);
      if (
        i < arr.length - 1 &&
        arr.length > 0 &&
        moment(minTime).tz('Europe/London').isSameOrBefore(timeEnd)
      ) {
        timesInBetween.push({ text: 'CLOSED', value: null });
      }
    });

    //remove 'CLOSED' label if that is first time
    if (
      timesInBetween &&
      timesInBetween.length > 1 &&
      timesInBetween[0] &&
      timesInBetween[0].text === 'CLOSED'
    ) {
      timesInBetween.shift();
    }
    if (timesInBetween.length === 0 && this.state.selectedRestaurant) {
      timesInBetween.push({ text: 'CLOSED', value: null });
    }
    return timesInBetween;
  };

  formatTimesForTree = (times) => {
    const groupByMonth = groupBy(['date']);
    const grouped = groupByMonth(times);
    return grouped;
  };

  formatPickupTimes = (store) => {
    const formatSelectOptionDays = [];
    const daysAhead = getConfig().general.collectAheadDays || 7;
    const deliveryOption = this.props.deliveryOption?.id;
    const disabledDates = getDisabledDatesForDeliveryOption(
      getConfig()?.disabled_dates,
      deliveryOption,
    );
    let picker = [];
    Array(daysAhead)
      .fill('')
      .forEach((day, i) => {
        let formatDay = moment().tz('Europe/London').add(i, 'days');
        if (i > 0) {
          formatDay = moment()
            .tz('Europe/London')
            .add(i, 'days')
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        }
        let formatDayName = formatDay.format('dddd');
        formatSelectOptionDays.push({ formatDayName, formattedDT: formatDay });
      });

    formatSelectOptionDays.map((option, i) => {
      if (store && isDefined(store.order_slot_lead_time)) {
        option.formattedDT.add(store.order_slot_lead_time, 'minutes');
      }
      const formatedOptionDate = option.formattedDT.format('DD-MM-YYYY');
      let daySlot = [];
      if (isDefined(disabledDates) && disabledDates.includes(formatedOptionDate)) {
        daySlot = [{ text: 'CLOSED', value: null }];
      } else {
        daySlot = this.formatDataForTime(store, option.formattedDT) || [];
      }
      daySlot.forEach((slot) => {
        let h = parseInt(slot.text.split(':')[0]);
        let m = parseInt(slot.text.split(':')[1]);
        let formattedDT = moment(option.formattedDT.hours(h).minutes(m)).tz('Europe/London');

        picker.push({
          formattedDT: formattedDT,
          // value: formattedDT.toDate(),
          date: formattedDT.format('ddd, DD MMM'),
          time: formattedDT.format('h:mm A'),
          slot: slot.text,
        });
      });
    });

    const formattedTreeDataSource = this.formatTimesForTree(picker);
    const asap_active = getConfig().general.asap_active || false;

    Object.keys(formattedTreeDataSource)
      .sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      })
      .map((day, index) => {
        if (
          formattedTreeDataSource[day].length > 0 &&
          index === 0 &&
          formattedTreeDataSource[day][0].slot !== 'CLOSED' &&
          asap_active
        ) {
          formattedTreeDataSource[day] = [
            {
              time: ASAP,
              formattedDT: formattedTreeDataSource[day][0].formattedDT,
              slot: formattedTreeDataSource[day][0].slot,
            },
            ...formattedTreeDataSource[day],
          ];
        }
        if (formattedTreeDataSource[day][0].slot === 'CLOSED') {
          formattedTreeDataSource[day][0] = { time: 'CLOSED' };
        } else {
          if (
            this.state.pickTime &&
            index === 0 &&
            this.state.pickDay === moment().tz('Europe/London').format('D')
          ) {
            const pickTimeIndex = formattedTreeDataSource[day].findIndex(
              (option) => option.slot === this.state.pickTime,
            );
            if (
              formattedTreeDataSource[day][pickTimeIndex + 2] &&
              pickTimeIndex + 1 <= formattedTreeDataSource[day].length - 1
            ) {
              let nextCollectionTime = [];
              for (
                let option = pickTimeIndex + 2;
                option < formattedTreeDataSource[day].length;
                option++
              ) {
                nextCollectionTime.push(
                  this.formatTime(
                    formattedTreeDataSource[day][option].slot,
                    formattedTreeDataSource[day][option].formattedDT,
                  ),
                );
              }
              Basket.setNextCollectionTime(nextCollectionTime);
            }
          }
        }
      });
    this.timePickerOptions = formattedTreeDataSource;
  };

  refreshTimeSlot = () => {
    if (this.timeSlotRef.current) {
      this.timeSlotRef.current.instance.init();
    }
  };
  selectStore = (store) => {
    if (store.id == this.state.selectedRestaurant) {
      this.setState({ selectedRestaurant: null, renderAgain: !this.state.renderAgain });
    } else {
      this.setState({ selectedRestaurant: store.id, renderAgain: !this.state.renderAgain });
    }
  };

  render() {
    const {
      __,
      restaurants,
      ikentooMenusForLocation,
      isChooseMenuModalOpen,
      deliveryOption,
      isTimeCollectionModalOpen,
      isBasketResetModalOpen,
    } = this.props;
    const { error, selectedRestaurant, pickTime, selectedIkentooMenu, asapTime } = this.state;
    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
    const store = restaurants.find((restaurant) => restaurant.id === selectedRestaurant) || null;
    const stores = restaurants || [];
    const formatedStores = this.formatDataForSelect(stores);
    this.formatPickupTimes(store);
    const menus = getMenusForSelectedTime(
      ikentooMenusForLocation,
      pickTime === ASAP ? asapTime : pickTime,
      store ? store.json_time_selector : [],
      this.state.dayOfTheWeek,
    );

    return (
      <Layout
        hideSecondToolbar
        headerWithTitle
        headerTitle={__(deliveryOption ? deliveryOption.label : '')}
        noPadding
        color="transparent"
        scrollY={false}
      >
        <Loading>
          <div className="flex-row-wrapper absolute-content">
            {!this.state.showTime ? (
              <div className="scrollable-y locations-wrapper">
                <div className="click-and-collect-title">
                  <Title className="web-only">
                    {this.props.scheduled ? __('Scheduled Click & Collect') : __('Click & Collect')}
                  </Title>
                  <NormalText>{__('Select a restaurant to collect your order')}</NormalText>
                </div>
                <Spacer size={1} />
                <div className="click-and-collect-locations">
                  <IonList className="box-wrapper box-list">
                    {formatedStores.map((store, i) => (
                      <IonItem onClick={() => this.selectStore(store)} lines="none" key={i}>
                        <IonLabel>
                          <StrongText>{__(store.name)}</StrongText>
                          <SmallText className="block">{__(store.address)}</SmallText>

                          <StrongText className="uppercase">{__(store.openLabel)}</StrongText>
                        </IonLabel>
                        <IonCheckbox
                          checked={selectedRestaurant && store.id === selectedRestaurant}
                          slot="start"
                          color="primary"
                        />
                      </IonItem>
                    ))}
                  </IonList>
                </div>
                <Spacer size={1} />
                <div className="click-and-collect-button-wrapper">
                  <IonButton
                    disabled={!selectedRestaurant}
                    expand="block"
                    color="primary"
                    onClick={() => this.selectRestaurant(selectedRestaurant)}
                  >
                    {__('Continue')}
                  </IonButton>
                </div>
              </div>
            ) : (
              <>
                <div className="scrollable-y">
                  <div className="click-and-collect-title">
                    <Title className="web-only">{__('Click & Collect')}</Title>
                    <NormalText>{__('Select a time to collect your order')}</NormalText>
                  </div>
                  <Spacer size={1} />
                  {this.state.selectedRestaurant && (
                    <mobiscroll.Treelist
                      display="inline"
                      ref={this.timeSlotRef}
                      layout="liquid"
                      focusOnClose={false}
                      themeVariant="light"
                      width={(180, 160)}
                      inputClass="datepicker"
                      circular={false}
                      placeholder={__('Select Collection Time')}
                      onSet={(e, instance) => {
                        const newInstance = new Object(instance);
                        instance.setVal(newInstance._tempWheelArray, true, true, true);
                        this.parseSelectedTime(this.timePickerOptions, instance._tempValue);
                      }}
                      onInit={(e, instance) => {
                        if (!this.state.pickTime) {
                          this.parseSelectedTime(this.timePickerOptions, instance._tempValue);
                        }
                      }}
                    >
                      {Object.keys(this.timePickerOptions).map((date, i) => (
                        <li key={i} data-val={date}>
                          {date}
                          <ul>
                            {this.timePickerOptions[date].map((time, j) => (
                              <li key={j} data-val={time.time}>
                                {time.time}
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </mobiscroll.Treelist>
                  )}
                  <Spacer size={1} />
                  <div className="click-and-collect-button-wrapper">
                    <IonButton
                      disabled={!pickTime}
                      expand="block"
                      color="primary"
                      className={this.state.continueButtonClicked ? 'unclicked' : ''}
                      onClick={() => this.chooseMenusForLocation()}
                    >
                      {__('Continue')}
                    </IonButton>
                  </div>
                </div>
              </>
            )}
            {/* <div className="scrollable-y">
              <Title>{deliveryOption ? deliveryOption.label : ''}</Title>
              <SmallText>{__('Start an order to collect from one of our shops')}</SmallText>
              <Spacer size={4} />
              <label className="select-picker-label" htmlFor="demo-non-form">
                {__('Shop')}
              </label>
              <SelectOption
                display="bubble"
                onSet={(e, a) => this.selectRestaurant(e, a)}
                data={this.formatDataForSelect(stores)}
                label="Location"
                value={this.state.selectedRestaurant}
                inputStyle="box"
                defaultValue={this.state.selectedRestaurant}
                placeholder={__(restaurantSelectPlaceholder)}
                setText={__('OK')}
                cancelText={__('Cancel')}
              />
              <p className="time-picker-label" htmlFor="demo-non-form">
                {__('Collection Time')}
              </p>
              {this.state.selectedRestaurant && (
                <mobiscroll.Treelist
                  theme="ios"
                  display="bubble"
                  ref={this.timeSlotRef}
                  layout="liquid"
                  focusOnClose={false}
                  themeVariant="light"
                  width={(180, 160)}
                  inputClass="datepicker"
                  circular={false}
                  placeholder={__('Select Collection Time')}
                  onSet={(e) => this.parseSelectedTime(this.timePickerOptions, e.valueText)}
                >
                  {Object.keys(this.timePickerOptions).map((date, i) => (
                    <li key={i} data-val={date}>
                      {date}
                      <ul>
                        {this.timePickerOptions[date].map((time, j) => (
                          <li key={j} data-val={time.time}>
                            {time.time}
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </mobiscroll.Treelist>
              )}

              {error ? (
                <IonItem>
                  <div tabIndex="-1"></div>
                  <FieldError className="field-error" value={__(error)} />
                </IonItem>
              ) : null}
            </div>
            {this.state.pickTime && store.info_text && (
              <p className="shop-description">{__(store.info_text)}</p>
            )}
            <div className="flex-min">
              <IonButton
                disabled={pickTime ? false : true}
                expand="block"
                color="primary"
                onClick={() => this.chooseMenusForLocation()}
              >
                {__('Continue')}
              </IonButton>
            </div> */}
          </div>
          <div
            className="click-collect-pickers-backdrop"
            style={{ display: isChooseMenuModalOpen ? '' : 'none' }}
            onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
          ></div>
          <div className={`click-collect-dialog ${animationMenuClass}`}>
            <Loading transparent> {null} </Loading>
            <div className="click-collect-dialog-layout sc-ion-modal-md">
              <div className="click-collect-dialog-header">
                <h3>{__('Choose menu')}</h3>
              </div>
              <div
                className="click-collect-dialog-closer"
                style={{ position: 'absolute', right: 0, top: 0 }}
                onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
              >
                <IonIcon icon={close} class="md hydrated"></IonIcon>
              </div>
              <div className="click-collect-dialog-content">
                <IonList lines="none" className="box-wrapper box-list">
                  <IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
                    {menus.length === 0 ? (
                      <NoData />
                    ) : (
                      menus.map((menu) => {
                        const { ikentooMenuId, menuName } = menu;
                        return (
                          <IonItem key={ikentooMenuId} lines="none">
                            <div tabIndex="-1"></div>
                            <IonRadio color="primary" slot="start" value={ikentooMenuId} />
                            <IonLabel className="ion-text-wrap" color="dark">
                              {menuName}
                            </IonLabel>
                          </IonItem>
                        );
                      })
                    )}
                  </IonRadioGroup>
                </IonList>
              </div>
              <div className="click-collect-dialog-action">
                {error ? (
                  <IonItem>
                    <div tabIndex="-1"></div>
                    <FieldError className="field-error" value={__(error)} />
                  </IonItem>
                ) : null}
                <IonButton
                  disabled={pickTime && menus.length > 0 ? false : true}
                  expand="block"
                  color="primary"
                  onClick={() => this.continueOnMenu()}
                >
                  {__('Next')}
                </IonButton>
              </div>
            </div>
          </div>
          <IonAlert
            isOpen={isTimeCollectionModalOpen}
            onDidDismiss={this.onRemoveCollectionTimeModal}
            header={__('Warning')}
            message={__('Collection Time is passed. Please set new collection time')}
            buttons={[
              {
                text: __('OK'),
                role: 'cancel',
                cssClass: 'secondary',
                handler: this.onRemoveCollectionTimeModal,
              },
            ]}
          />
          <IonAlert
            isOpen={this.state.showValidationAlert}
            onDidDismiss={() => this.setShowValidationAlert(false)}
            header={__('Error')}
            message={__('Some items were unavailable at this time')}
            buttons={[
              {
                text: __('Start new order'),
                cssClass: 'secondary',
                handler: () => forwardTo('/order'),
              },
            ]}
          />
        </Loading>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const {
    auth,
    isChooseMenuModalOpen,
    orderProductionMins,
    isTimeCollectionModalOpen,
    isBasketResetModalOpen,
  } = state.common;
  const { restaurants, ikentooMenu, isShowTimePicker, ikentooMenusForLocation } = state.restaurants;
  const { deliveryOption } = state.orders;
  return {
    auth,
    isChooseMenuModalOpen: isChooseMenuModalOpen,
    restaurants: restaurants || [],
    ikentooMenu: ikentooMenu || {},
    ikentooMenusForLocation: ikentooMenusForLocation || [],
    profile: state.profile.profile,
    isShowTimePicker: isShowTimePicker,
    deliveryOption,
    orderProductionMins: orderProductionMins,
    isTimeCollectionModalOpen,
    active_subscription: state.profile.profile.active_subscription,
    subscriptions: state.subscription.subscriptions,
    allergens: state.restaurants.allergens,
    profile: state.profile.profile,
    isBasketResetModalOpen,
  };
};

const dispatchToProps = { setSelectedSubscription };

export default connect(stateToProps, dispatchToProps)(withTranslation(ClickAndCollect));
