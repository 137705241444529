/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { IonButton, IonItem, IonInput, IonModal } from '@ionic/react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
// import { close } from 'ionicons/icons'
import { withTranslation } from '../../lib/translate';
import { deepIsDefined, forwardTo, goBack, isDefined, validateForm } from '../../lib/utils';
import { sendVoucherCode, checkDiscount, showToast, getVouchers } from '../../store/actions';
import Layout from '../../components/layout';
import {
  Title,
  Spacer,
  FieldError /*, NormalText, SmallText*/,
  NormalText,
  Subtitle,
  StrongText,
} from '../../components/common';

import Basket from '../../lib/basket';
import Modal from '../../components/modal';
import { getConfig } from '../../appConfig';
import './index.css';
import Header from '../../components/header';

class ApplyVouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discounts: null,
      selectedVoucher: null,
      showModal: null,
      voucherCode: '',
      disabledAppliedButton: false,
      selectedVoucherTerms: null,
      formErrors: {
        voucherCode: '',
      },
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getVouchers());
  }

  setVoucher = (voucher) => {
    const { dispatch, __, profile } = this.props;
    this.setState({ selectedVoucher: voucher }, () => {
      const { vouchers, applicableVouchers } = this.props;
      const { selectedVoucher } = this.state;
      const appliedVoucher = vouchers.find((voucher) => voucher.id === selectedVoucher.id);
      if (appliedVoucher) {
        const calculateTaxUsingPos = getConfig()?.flags?.calculateTaxUsingPos && profile.is_corporate;
        const voucherDataFromBasket = Basket.parseBasketForCheckVouchers();
        dispatch(checkDiscount({ ...voucherDataFromBasket, selectedVoucher, calculateTaxUsingPos }));
      } else {
        dispatch(showToast(__('Voucher is not available.'), 'warning'));
      }
    });
  };

  // addVoucher = () => {
  //   const { selectedVoucher } = this.state;
  //   const { vouchers, applicableVouchers, dispatch, __ } = this.props;
  //   const appliedVoucher = vouchers.find((voucher) => voucher.id === selectedVoucher.id);
  //   const applicableVoucher = applicableVouchers.find(
  //     (applicableVoucher) => applicableVoucher.id === selectedVoucher.id,
  //   );
  //   if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
  //     this.setState({ selectedVoucher: null }, () => {
  //       Basket.addVoucher(appliedVoucher, applicableVoucher);
  //       goBack();
  //     });
  //   } else {
  //     dispatch(
  //       showToast(
  //         __('Your basket is higher then voucher cost. Please add more items in basket'),
  //         'warning',
  //       ),
  //     );
  //   }
  // };

  showModal = (show) => {
    this.setState({ showModal: show });
    this.clearFields();
  };

  clearFields = () => {
    this.setState({ voucherCode: '' });
  };

  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      dispatch(sendVoucherCode(data, true));
      this.showModal(false);
    }
  };

  filterVouchers = (vouchers, appliedVouchers) => {
    const { applicableVouchers, availableBalance } = this.props;
    let filteredVouchers = [];
    if (applicableVouchers && applicableVouchers.length > 0) {
      applicableVouchers.forEach((discount) => {
        let findedVoucher = vouchers.find((voucher) => voucher.id === discount.id);
        if (getConfig().vouchersType === 2) {
          if (findedVoucher && availableBalance > findedVoucher.cost) {
            filteredVouchers.push(findedVoucher);
          }
        } else {
          if (findedVoucher) {
            filteredVouchers.push(findedVoucher);
          }
        }
      });
    }
    if (appliedVouchers && appliedVouchers.length > 0) {
      const ids = appliedVouchers.map((i) => i.id);
      return filteredVouchers.filter((filteredVoucher) => ids.indexOf(filteredVoucher.id) === -1);
    }
    return filteredVouchers;
  };

  render() {
    const { __, vouchers, applicableVouchers } = this.props;
    const {
      selectedVoucher,
      voucherCode,
      showModal,
      disabledAppliedButton,
      selectedVoucherTerms,
    } = this.state;

    return (
      <>
        <Loading>
          <Layout hideSecondToolbar headerTitle={__('Apply Vouchers')}>
            <div className="flex-row-wrapper absolute-content scrollable-y">
              <div className="redeem-voucher-field-wrapper">
                <div className="input-field-container">
                  <NormalText className="bold">{__('Enter Voucher Code')}</NormalText>
                  <IonItem className="input-field-wrapper" lines="none">
                    <IonInput
                      value={voucherCode}
                      onIonInput={(e) =>
                        this.setState({ voucherCode: e.target.value.toUpperCase() })
                      }
                      placeholder={__('Voucher code')}
                    />
                  </IonItem>
                </div>
                <IonButton onClick={() => this.sendCode()} color="primary">
                  {__('Submit')}
                </IonButton>
              </div>
              <Spacer />
              {vouchers.length > 0 && <StrongText>{__('Available Vouchers')}</StrongText>}
              <div>
                {vouchers.length === 0 ? (
                  <p style={{ textAlign: 'center' }}>{__('No data')}</p>
                ) : null}
                {vouchers.length > 0 &&
                  vouchers.map((filteredVoucher, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          onClick={() => this.setVoucher(filteredVoucher)}
                          className={
                            selectedVoucher && selectedVoucher.id === filteredVoucher.id
                              ? 'voucher-content apply-voucher'
                              : 'voucher-content apply-voucher-box'
                          }
                        >
                          <img alt="" src={filteredVoucher.reward.image} width="100%" />
                          <div>
                            <div className="centered">
                              <p>{__(filteredVoucher.reward.name)}</p>
                            </div>
                          </div>
                        </div>
                        <IonButton
                          expand="block"
                          color="dark"
                          fill="clear"
                          className="link underlined"
                          onClick={() => {
                            this.setState({ selectedVoucherTerms: filteredVoucher });
                          }}
                        >
                          {__('View T&Cs')}
                        </IonButton>
                      </>
                    );
                  })}
              </div>
              <div className="flex-min">
                {/* <IonButton
                  color="secondary"
                  disabled={applicableVouchers.length > 0  ? false : true}
                  onClick={() => {
                    this.addVoucher();
                  }}
                  className={
                    disabledAppliedButton ? 'box-button-color-tertiary' : 'box-button-color'
                  }
                  size="full"
                  shape="round"
                >
                  {__('Apply Vouchers')}
                </IonButton> */}
                <IonButton expand="full" onClick={goBack} color="secondary">
                  {__('Back')}
                </IonButton>
              </div>
            </div>
          </Layout>
          <Modal
            className="apply-voucher-modal"
            isOpen={!!showModal}
            onDidDismiss={() => this.setState({ showModal: null })}
            title={__('Enter code')}
          >
            <div className="flex-row-wrapper">
              <div className="centered flex-row-wrapper">
                <div className="pad20t centered scrollable-y">
                  <p>{__('Enter a valid voucher code below to receive your reward')}</p>
                  <div className="lefted padded voucher-item">
                    <IonItem style={{ '--min-height': '32px' }}>
                      <IonInput
                        value={voucherCode}
                        onIonInput={(e) =>
                          this.setState({ voucherCode: e.target.value.toUpperCase() })
                        }
                        placeholder={__('Voucher code')}
                      />
                    </IonItem>
                    <FieldError
                      className="field-error"
                      value={__(this.state.formErrors.voucherCode)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-min">
                <IonButton onClick={() => this.sendCode()} expand="full" color="secondary">
                  {__('Submit')}
                </IonButton>
              </div>
            </div>
          </Modal>
          {isDefined(selectedVoucherTerms) ? (
            <Modal
              isOpen={isDefined(selectedVoucherTerms) && selectedVoucherTerms !== null}
              backdrop-dismiss="true"
              onDidDismiss={() => this.setState({ selectedVoucherTerms: null })}
            >
              <div className="voucher-info-wrapper">
                <div className="voucher-intro-modal-content">
                  <div className="modal-text">
                    <Title className="ion-text-center uppercase voucher-info-title">
                      {selectedVoucherTerms?.reward?.name}
                    </Title>
                    <NormalText
                      className="ion-text-center no-margin-bottom"
                      tag="p"
                      color="primary"
                    >
                      {__(selectedVoucherTerms?.reward?.small_print)}
                    </NormalText>
                    <Subtitle
                      className="ion-text-center no-margin-bottom"
                      color="primary"
                      onClick={() => {
                        forwardTo('/terms');
                      }}
                    >
                      {__('Terms & Conditions')}
                    </Subtitle>
                    <NormalText className="ion-text-center" tag="p" color="primary">
                      {__(selectedVoucherTerms?.reward?.description)}
                    </NormalText>
                  </div>
                  <Spacer />
                </div>
                <div className="flex-min">
                  <IonButton
                    expand="block"
                    color="primary"
                    className="default-button modal-button"
                    onClick={() => this.setState({ selectedVoucherTerms: null })}
                  >
                    {__('Close')}
                  </IonButton>
                </div>
              </div>
            </Modal>
          ) : null}
        </Loading>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    availableBalance: deepIsDefined(store, 'profile.profile.available_balance')
      ? store.profile.profile.available_balance
      : 0,
    basketUpadated: store.orders.basketUpdated,
    vouchers: store.profile.vouchers || null,
    applicableVouchers: store.profile.applicableVouchers || null,
    profile: store.profile?.profile || {}
  };
};

export default connect(mapStateToProps)(withTranslation(ApplyVouchers));
