import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonItem,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
  IonAlert,
  IonIcon,
} from '@ionic/react';
import Layout from '../../components/layout';
import { getIkentooMenu, getIkentooMenusForLocation } from '../../store/restaurants/actions';
import { setCommonModal, showToast } from '../../store/common/actions';
import {
  FieldError,
  NormalText,
  Sectiontitle,
  SmallText,
  Spacer,
  Title,
} from '../../components/common';
import { withTranslation } from '../../lib/translate';
import {
  isDefined,
  checkForDeliveryOption,
  isObject,
  isArray,
  getDistance,
  groupBy,
  weekdays,
  forwardToDeliveryOption,
  forwardTo,
  cutoffTime,
  isEmptyObject,
  deepCopy,
  parseAllergenData,
  getDisabledDatesForDeliveryOption,
} from '../../lib/utils';
import moment from '../../lib/moment';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import { setDeliveryOption } from '../../store/actions';
import './index.css';
import NoData from '../../components/noData';
import { getConfig } from '../../appConfig';
import mobiscroll from '@mobiscroll/react';
import api from '../../lib/api';
import { SET_RESTAURANT_PROP } from '../../store/constants';
import { close } from 'ionicons/icons';

export const formatDataForTime = (store, minDate, selectedRestaurant) => {
  let timesInBetween = [];
  let storeTimes = [];
  const scheduledDeliveryPeriod = Basket.getDeliveryMenu()?.slot_duration_mins ?? 5;
  const minDT = minDate.format('HH:mm');
  const date = moment(minDate);
  const dow = 'w' + date.day();
  const period = scheduledDeliveryPeriod;

  if (store) {
    let slot = Basket.getDeliveryMenu()?.slots[date.format('dddd').toLowerCase()];
    if (slot?.start_time) {
      storeTimes.push({
        d: dow,
        start: slot.start_time,
        end: slot.end_time,
        prep_time: slot.prep_time,
        min_prep_time: slot.min_prep_time,
      });
    }
  }

  function returnTimesInBetween(start, end, prep_time, cnt, min_prep_time) {
    let _start = moment()
      .hours(parseInt(start.split(':')[0]))
      .minutes(parseInt(start.split(':')[1]))
      .seconds(0);
    let _end = moment()
      .hours(parseInt(end.split(':')[0]))
      .minutes(parseInt(end.split(':')[1]))
      .seconds(0);
    for (let _time = _start; _time.isSameOrBefore(_end); _time.add(period, 'minutes')) {
      timesInBetween.push({
        text: _time.format('HH:mm'),
        value: _time.format('HH:mm'),
        prep_time,
        min_prep_time,
      });
    }

    //if we have no oppning times, just add 'CLOSED' label to the picker
    if (
      timesInBetween &&
      timesInBetween.length > 1 &&
      timesInBetween[0] &&
      timesInBetween[0].text === 'Closed'
    ) {
      timesInBetween.shift();
    }
    //if we have no oppning times, just add 'CLOSED' label to the picker
    if (isDefined(store) && timesInBetween.length === 0) {
      timesInBetween.push({ text: 'Closed', value: null });
    }
    return timesInBetween;
  }

  storeTimes.forEach((storeT, i, arr) => {
    returnTimesInBetween(storeT?.start, storeT?.end, storeT?.prep_time, i, storeT?.min_prep_time);
    let minH = parseInt(minDT.split(':')[0]);
    let minM = parseInt(minDT.split(':')[1]);
    let endTimeH = parseInt(storeT?.end.split(':')[0]);
    let endTimeM = parseInt(storeT?.end.split(':')[1]);
    let minTime = date.hours(minH).minutes(minM);
    let timeEnd = date.hours(endTimeH).minutes(endTimeM);
    if (i < arr.length - 1 && arr.length > 0 && moment(minTime).isSameOrBefore(timeEnd)) {
      timesInBetween.push({ text: 'Closed', value: null });
    }
  });

  //remove 'CLOSED' label if that is first time
  if (
    timesInBetween &&
    timesInBetween.length > 1 &&
    timesInBetween[0] &&
    timesInBetween[0].text === 'Closed'
  ) {
    timesInBetween.shift();
  }
  if (timesInBetween.length === 0 && selectedRestaurant) {
    timesInBetween.push({ text: 'Closed', value: null });
  }
  return timesInBetween;
};

class ScheduledDeliveryTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pickTime: null,
      selectedDate: '',
      error: '',
      isLocationAllowed: false,
      orderPassed: false,
    };
    this.dateSlotRef = React.createRef();
    this.timeSlotRef = React.createRef();
    this.timePickerOptions = {};
  }

  componentDidMount() {
    const { deliveryOption } = this.props;
    this.checkDelivery();
    let dataForDeliveryOption = null;
    if (!deliveryOption) {
      const order_type = Basket.getOrderType();
      const deliveryConfig = getConfig().delivery;
      Object.keys(deliveryConfig).map((key) => {
        if (deliveryConfig[key].id === order_type.toLowerCase()) {
          dataForDeliveryOption = deliveryConfig[key];
        }
      });
      this.props.dispatch(setDeliveryOption(dataForDeliveryOption));
    }
    const scheduledDelivery = deliveryOption
      ? deliveryOption.id === 'delivery'
        ? true
        : false
      : false;
    // if (scheduledDelivery || dataForDeliveryOption) {
    //   // this.pickerRefCharter.current.instance.hide();
    // } else {
    this.props.dispatch(
      getIkentooMenusForLocation(
        Basket.getRestaurant() ? Basket.getRestaurant().business_location_id : null,
        { active_menu: Basket.getDeliveryMenu() },
        true,
      ),
    );
    // }
    this.parseSelectedTime('init')
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deliveryOption !== this.props.deliveryOption) {
      checkForDeliveryOption(
        this.props.deliveryOption ? this.props.deliveryOption : Basket.getDeliveryOption(),
        '/delivery',
      );
    }
    if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
      if (this.props.ikentooMenusForLocation[0]) {
        this.setState({ selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId });
      }
    }
  }

  continueOnMenu = (ikentooMenu) => {
    const { restaurants, dispatch } = this.props;
    const { selectedIkentooMenu } = this.state;
    const selectedRestaurant = Basket.getRestaurant();

    if (selectedRestaurant && (ikentooMenu || selectedIkentooMenu)) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant.id,
      );

      const businessLocationId = choosenRestaurant.business_location_id;
      dispatch(getIkentooMenu(selectedIkentooMenu || ikentooMenu, businessLocationId));
    } else {
      this.setState({ error: 'Please select location menu' });
    }
  };
  setShowValidationAlert = (flag) => this.setState({ showValidationAlert: flag });

  chooseMenusForLocation = async () => {
    const { restaurants, dispatch, __ } = this.props;
    const { pickTime } = this.state;
    const selectedRestaurant = Basket.getRestaurant();
    if (selectedRestaurant && pickTime) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant.id,
      );
      const businessLocationId = choosenRestaurant.business_location_id;
      if (this.props.location?.state?.selectedRestaurant?.items.length > 0) {
        let ikentooMenu = {};
        try {
          ikentooMenu = await api.getIkenooMenu(
            Basket.getDeliveryMenu()?.menu_id,
            businessLocationId,
          );
        } catch (error) {
          dispatch(showToast(__('Get restaurant menu error.'), 'warning'));
        }
        if (!isEmptyObject(ikentooMenu)) {
          dispatch({
            type: SET_RESTAURANT_PROP,
            key: 'ikentooMenu',
            value: ikentooMenu,
          });
          Basket.setMenu(ikentooMenu.ikentooMenuId);
          const categoryItems = ikentooMenu.menuEntry || ikentooMenu.menuEntryGroups || null;
          const items = Basket.flattenMenuItems(deepCopy(categoryItems));
          if (items.length > 0) {
            let validationStatus = { notBasketEmpty: false, validationError: false };
            this.props.location.state.selectedRestaurant.items.map((newBasketItem, index) => {
              let foundItem = items.find((i) => i.sku === newBasketItem.item.sku);
              if (foundItem && Basket.isProductEnabled(foundItem)) {
                for (let i = 0; i < newBasketItem.selectedChoices.length; i++) {
                  for (let j = 0; j < newBasketItem.selectedChoices[i].length; j++) {
                    if (Basket.isProductEnabled(newBasketItem.selectedChoices[i][j])) {
                      let modifierSku = newBasketItem.selectedChoices[i][j].sku;
                      const foundModifier = foundItem.menuDealGroups[i].items.find(
                        (el) => el.sku == modifierSku,
                      );
                      newBasketItem.selectedChoices[i][j] = foundModifier;
                    } else {
                      newBasketItem.selectedChoices[i][j] = false;
                    }
                  }
                }
                newBasketItem.selectedChoices = newBasketItem.selectedChoices.map((el) => {
                  return el.filter(Boolean);
                });
                newBasketItem.item = foundItem;
                Basket.addToBasket(newBasketItem);
                let item = newBasketItem.item;
                let allergens = this.props.allergens;
                let profile = this.props.profile;
                let newArr = parseAllergenData(profile, item, allergens);
                let allergensCodes =
                  newBasketItem?.item?.itemRichData?.allergenCodes?.length > 0
                    ? newBasketItem.item.itemRichData.allergenCodes
                    : [];
                if (allergensCodes.length > 0) {
                  let allergensData = [{ allergens: newArr }, { sku: newBasketItem.item.sku }];
                  Basket.setAllergen(allergensData);
                }
                validationStatus.notBasketEmpty = true;
              } else {
                validationStatus.validationError = true;
              }
            });
            if (validationStatus.notBasketEmpty) {
              if (validationStatus.validationError) {
                dispatch(
                  showToast(
                    __(
                      'Some items were not added to your basket as they are currently unavailable',
                    ),
                    'warning',
                  ),
                );
              }
              forwardTo('/order-summary', { skipBackToThePreviousPage: false });
            } else {
              this.setShowValidationAlert(true);
            }
          }
        } else {
          dispatch(getIkentooMenu(Basket.getDeliveryMenu()?.menu_id, businessLocationId));
        }
      } else {
        dispatch(
          getIkentooMenusForLocation(businessLocationId, {
            pickTime,
            active_menu: Basket.getDeliveryMenu(),
            isDelivery: true,
          }),
        );
      }

      // this.setState({ orderPassed: true });
    } else if (!selectedRestaurant) {
      this.setState({ error: 'Please select location' });
    } else {
      this.setState({ error: 'Please select pickup time' });
    }
  };

  changeIkentooMenus = (event) =>
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
      Basket.setMenu(event.detail.value);
    });
  parseSelectedTime(selected) {
    let selectedTimeSlot =
      selected !== 'init' ? selected.match(/\d{1,2}:\d{2} (AM|PM)/)?.[0] || 'CLOSED' : 'init';
    if (selectedTimeSlot !== 'CLOSED') {
      const selectedDate = selected.replace(' ' + selectedTimeSlot, '');
      const timePickerOptionsKeys = Object.keys(this.timePickerOptions);
      const selectedTime =
        selectedDate === 'init'
          ? this.timePickerOptions && timePickerOptionsKeys.length > 0
            ? this.timePickerOptions[timePickerOptionsKeys[0]][0]
            : {}
          : this.timePickerOptions[selectedDate].find((option) => option.time === selectedTimeSlot);
      if (selectedTime) {
        this.setState({ pickTime: selectedTime?.snoozed ? null : selectedTime.slot }, () => {
          Basket.setCollectionTime(moment(selectedTime.formattedDT).tz('Europe/London'));
          Basket.setCutoffTime(
            moment(selectedTime.formattedDT)
              .tz('Europe/London')
              .add(-selectedTime.prepTime, 'hours')
              .unix(),
          );
          Basket.setOrderType(this.props.deliveryOption.id);
        });
      }
    } else {
      this.setState({ pickTime: null });
    }
  }

  getcutoffTime = (deliveryTime, slots) => {
    let day = moment(deliveryTime).format('dddd');
    let hour = moment(deliveryTime).format('HH:mm');
    let deliveryTimeFormated = moment(deliveryTime);
    let slot = null;
    Object.keys(slots).map((key) => {
      if (key === day.toLowerCase()) {
        slot = [slots[key]];
      }
    });
    if (slot) {
      let slotRes = slot.find((s) =>
        moment(s.start_time, 'HH:mm').format('HH:mm') === moment(hour, 'HH:mm').format('HH:mm')
          ? s
          : null,
      );
      let cutoffTime = slotRes
        ? moment(deliveryTimeFormated).subtract(slotRes.prep_time, 'hours').unix()
        : moment(deliveryTimeFormated).unix();
      return cutoffTime;
    }
    return null;
  };
  parseSelectedDate(selected) {
    this.setState(
      {
        ...this.state,
        selectedDate: selected === 'init' ? Object.keys(this.timePickerOptions)[0] : selected,
      },
      () => {
        this.refreshTimeSlot();
      },
    );
  }

  formatTimesForTree = (times) => {
    const groupByMonth = groupBy(['date']);
    const grouped = groupByMonth(times);
    return grouped;
  };

  formatPickupTimes = (store) => {
    const formatSelectOptionDays = [];
    const daysAhead = Basket.getDeliveryMenu()?.days_ahead || 7;
    let picker = [];
    const deliveryOption = this.props.deliveryOption?.id;

    const disabledDates = getDisabledDatesForDeliveryOption(
      getConfig()?.disabled_dates,
      deliveryOption,
    );
    Array(daysAhead)
      .fill('')
      .forEach((day, i) => {
        let formatDay = moment().tz('Europe/London').add(i, 'days');

        formatDay = moment()
          .tz('Europe/London')
          .add(i, 'days')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        let formatDayName = formatDay.format('dddd');
        formatSelectOptionDays.push({ formatDayName, formattedDT: formatDay });
      });

    formatSelectOptionDays.map((option, i) => {
      // const daySlot = formatDataForTime(store, option.formattedDT, Basket.getRestaurant()) || [];

      const formatedOptionDate = option.formattedDT.format('DD-MM-YYYY');
      let daySlot = [];
      if (isDefined(disabledDates) && disabledDates.includes(formatedOptionDate)) {
        daySlot = [...daySlot];
      } else {
        daySlot = formatDataForTime(store, option.formattedDT, Basket.getRestaurant()) || [];
      }
      daySlot.forEach((slot) => {
        let h = parseInt(slot.text.split(':')[0]);
        let m = parseInt(slot.text.split(':')[1]);
        let formattedDT = moment(option.formattedDT.hours(h).minutes(m)).tz('Europe/London');
        let prepTime = moment().tz('Europe/London').add(slot.prep_time, 'hours');
        let minPrepTime = moment().tz('Europe/London').add(slot.min_prep_time, 'hours');
        if (prepTime.isBefore(formattedDT) && minPrepTime.isBefore(formattedDT)) {
          picker.push({
            formattedDT: formattedDT,
            // value: formattedDT.toDate(),
            date: formattedDT.format('ddd, DD MMM YYYY'),
            time: formattedDT.format('h:mm A'),
            slot: slot.text,
            prepTime: slot.prep_time,
          });
        }
      });
    });
    let unavailable_dates = null;
    if (Basket.getDeliveryMenu()) {
      unavailable_dates = Basket.getDeliveryMenu()?.unavailable_dates?.map((slot) =>
        moment(slot, 'DD/MM/YYYY').format('ddd, DD MMM YYYY'),
      );
    }

    const formattedTreeDataSource = this.formatTimesForTree(picker);

    Object.keys(formattedTreeDataSource).map((day, index) => {
      if (formattedTreeDataSource[day][0].slot === 'Closed') {
        delete formattedTreeDataSource[day];
      } else {
        if (
          unavailable_dates &&
          unavailable_dates.length > 0 &&
          unavailable_dates.indexOf(day) > -1
        ) {
          delete formattedTreeDataSource[day];
          // formattedTreeDataSource[day] = [{ time: 'CLOSED' }];
        }
        let slot = Basket.getDeliveryMenu()?.slots[
          moment(day, 'ddd, DD MMM YYYY').format('dddd').toLowerCase()
        ];
        if (slot?.start_time && Basket.getDeliveryMenu()) {
          let cutoff_time = moment(day, 'ddd, DD MMM YYYY')
            .hours(parseInt(slot.start_time.split(':')[0]))
            .minutes(parseInt(slot.start_time.split(':')[1]))
            .seconds(0)
            .add(-slot.prep_time, 'hours');
          if (moment().tz('Europe/London').isAfter(cutoff_time)) {
            delete formattedTreeDataSource[day];
          }
        }

        if (formattedTreeDataSource[day]) {
          formattedTreeDataSource[day] = formattedTreeDataSource[day].map((dt) => {
            const slotEndTime = Number(slot?.end_time.replace(':', ''));
            const slotPlusSlotDuration = moment(dt.formattedDT).add(
              Basket.getDeliveryMenu()?.slot_duration_mins,
              'minutes',
            );

            const slotPlusSlotDurationFormated = Number(
              moment(slotPlusSlotDuration).format('HH:mm').replace(':', ''),
            );
            if (slotEndTime >= slotPlusSlotDurationFormated && slotPlusSlotDurationFormated !== 0) {
              return dt;
            } else {
              return { time: 'CLOSED' };
            }
          });

          formattedTreeDataSource[day].filter(Boolean);
        }
      }
    });

    const snoozedTimes = store?.snoozed_times?.['scheduled-delivery'] || [];
    snoozedTimes.map((snoozeTime) => {
      let _snoozeTime = moment.utc(snoozeTime, 'ddd, DD MMM YYYY-HH:mm').local();
      let temp_data = formattedTreeDataSource[_snoozeTime.format('ddd, DD MMM')];
      let pos = temp_data?.findIndex((item) => item.slot === _snoozeTime.format('HH:mm'));
      if (pos > -1) {
        temp_data[pos] = { ...temp_data[pos], snoozed: true };
      }
    });
    this.timePickerOptions = formattedTreeDataSource;
    // this.refreshTimeSlot()
  };

  refreshTimeSlot = () => {
    if (this.timeSlotRef.current) {
      this.timeSlotRef.current.instance.init();
    }
  };

  refreshDateSlot = () => {
    if (this.dateSlotRef.current) {
      this.dateSlotRef.current.instance.init();
    }
  };
  printSlotTimes = (data, store) => {
    if (data) {
      if (data[0].time == 'CLOSED') {
        return (
          <li data-val={data[0].time}>
            <span className={`item-date-picker`}>{'UNAVAILABLE'}</span>
          </li>
        );
      } else {
        const result = data.filter((dt) => dt.time !== 'CLOSED');
        return result.map((el, i) => {
          return (
            <li key={i} data-val={el.time}>
              <span className={`item-date-picker`}>
                {el.formattedDT.format('h:mm A') +
                  '-' +
                  moment(el.formattedDT)
                    .add(Basket.getDeliveryMenu()?.slot_duration_mins, 'minutes')
                    .format('h:mm A')}
              </span>
            </li>
          );
        });
      }
    }
  };
  checkDelivery = () => {
    if (!Basket.getDeliveryOption() || (!Basket.getDeliveryAddress() && !Basket.getPickUpPoint())) {
      forwardToDeliveryOption();
    }
  };
  render() {
    const { __, ikentooMenusForLocation, isChooseMenuModalOpen, deliveryOption } = this.props;
    const { error, pickTime, selectedIkentooMenu } = this.state;
    const store = Basket.getRestaurant();
    this.formatPickupTimes(store);

    const menus = ikentooMenusForLocation.filter(
      (menu) => menu.ikentooMenuId === Basket.getDeliveryMenu()?.menu_id,
    );

    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
    /**/
    return (
      <>
        <Layout
          hideSecondToolbar={true}
          headerTitle={__('Scheduled Delivery Order')}
          headerWithTitle
          className="click-collect-page"
          color="transparent"
        >
          <Loading>
            <div className="absolute-content scrollable-y">
              <Title >{__('Choose your delivery time slot')}</Title>
              <NormalText className='block'>{__('We always aim to deliver your order on time, but occasionally we can be up to 30 minutes early. ')}</NormalText>
              <Spacer size={1} />
              <NormalText className='block'>{__('Please bear in mind when choosing your time slot, especially for breakfast orders as there will need to be somebody in the office to receive it!')}</NormalText>
              <Spacer size={1} />

              <div className="click-collect-locations">
                <mobiscroll.Treelist
                  display="inline"
                  layout="liquid"
                  showInput={false}
                  circular={false}
                  ref={this.timeSlotRef}
                  focusOnClose={false}
                  themeVariant="light"
                  theme="ios"
                  width={(280, 260)}
                  placeholder={__('Select Collection Time')}
                  onSet={(e, instance) => {
                    // Workaround for treelist picker if date is only updated
                    const newInstance = new Object(instance);
                    instance.setVal(newInstance._tempWheelArray, true, true, true);
                    this.parseSelectedTime(instance._tempValue);
                  }}
                  scrollLock
                >
                  {Object.keys(this.timePickerOptions).map((date, i) => (
                    <li key={i} data-val={date}>
                      <span
                        className={`item-date-picker ${this.timePickerOptions[date][0].time === 'CLOSED' && 'striked'
                          }`}
                      >
                        {moment(date, 'ddd, DD MMM YYYY').format('ddd, DD MMM')}
                      </span>
                      <ul data-val={null}>
                        {this.timePickerOptions[date][0].time == 'CLOSED' ? (
                          <>
                            <li>
                              <span>{__('UNAVAILABLE')}</span>
                            </li>
                          </>
                        ) : (
                          <>
                            {this.timePickerOptions[date]
                              .filter((el) => el.time !== 'CLOSED')
                              .map((time, j) => (
                                <li key={j} data-val={time.time}>
                                  <span className={`item-date-picker ${time.snoozed && 'striked'}`}>
                                    {time.time === 'CLOSED' ? 'UNAVAILABLE' : time.time}
                                  </span>
                                </li>
                              ))}
                          </>
                        )}
                      </ul>
                    </li>
                  ))}
                </mobiscroll.Treelist>
              </div>
              <Spacer size={1} />
              <div className="click-collect-button">
                <IonButton
                  disabled={!pickTime}
                  expand="block"
                  color="secondary"
                  onClick={() => this.chooseMenusForLocation()}
                >
                  {__('Continue')}
                </IonButton>
              </div>
            </div>
          </Loading>
        </Layout>
        <div
          className="click-collect-pickers-backdrop"
          style={{ display: isChooseMenuModalOpen ? '' : 'none' }}
          onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
        ></div>
        <div className={`click-collect-dialog ${animationMenuClass}`}>
          <Loading> {null} </Loading>
          <div className="click-collect-dialog-layout sc-ion-modal-md">
            <div className="click-collect-dialog-header">
              <h3>{__('Choose menu')}</h3>
            </div>
            <div
              className="click-collect-dialog-closer"
              style={{ position: 'absolute', right: 0, top: 0 }}
              onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
            >
              <IonIcon
                icon={close} 
                className="md hydrated"
              ></IonIcon>
            </div>
            <div className="click-collect-dialog-content">
              <IonList lines="none">
                <IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
                  {menus.length === 0 ? (
                    <NoData />
                  ) : (
                    menus.map((menu) => {
                      const { ikentooMenuId, menuName } = menu;
                      return (
                        <IonItem key={ikentooMenuId} lines="full">
                          <div tabIndex="-1"></div>
                          <IonLabel className="ion-text-wrap">
                            <Sectiontitle>{menuName}</Sectiontitle>
                          </IonLabel>
                          <IonRadio color="secondary" slot="start" value={ikentooMenuId} />
                        </IonItem>
                      );
                    })
                  )}
                </IonRadioGroup>
              </IonList>
            </div>
            <div className="click-collect-dialog-action">
              {error ? (
                <IonItem>
                  <div tabIndex="-1"></div>
                  <FieldError className="field-error" value={__(error)} />
                </IonItem>
              ) : null}
              <IonButton
                disabled={pickTime && menus.length > 0 ? false : true}
                expand="block"
                color="secondary"
                onClick={() => this.continueOnMenu()}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        </div>
        <IonAlert
          isOpen={this.state.showValidationAlert}
          onDidDismiss={() => this.setShowValidationAlert(false)}
          header={__('Error')}
          message={__('Some items were unavailable at this time')}
          buttons={[
            {
              text: __('Start new order'),
              cssClass: 'secondary',
              handler: () => {
                forwardTo('/delivery-options');
              },
            },
          ]}
        />
      </>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isChooseMenuModalOpen, orderProductionMins } = state.common;
  const { restaurants, ikentooMenu, isShowTimePicker, ikentooMenusForLocation } = state.restaurants;
  const { deliveryOption } = state.orders;
  return {
    auth,
    isChooseMenuModalOpen: isChooseMenuModalOpen,
    restaurants: restaurants || [],
    ikentooMenu: ikentooMenu || {},
    ikentooMenusForLocation: ikentooMenusForLocation || [],
    profile: state.profile.profile,
    isShowTimePicker: isShowTimePicker,
    deliveryOption,
    orderProductionMins: orderProductionMins,
  };
};

export default connect(stateToProps)(withTranslation(ScheduledDeliveryTime));
