import React from 'react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { Title, NormalText, Spacer, StrongText } from '../../components/common';
import { IonButton, IonAlert } from '@ionic/react';
import Box, { BoxHeader } from '../../components/box';
import OrderContent from '../../components/orderContent';
import Basket, { createNewBasketInstance } from '../../lib/basket';
import { checkIfOrderCanBeCancelled, forwardTo } from '../../lib/utils';
import { checkCancelOrder, setOrdersProp } from '../../store/orders/actions';
import moment from '../../lib/moment';
import { connect } from 'react-redux';
import AmountPaid from '../../components/amountPaid';
import SwipableTabs from '../../components/swipeableTabs';
import OrderStatus, { lastEventForPill, updateOrder } from '../../components/orderStatus';
import { PillStatusGroup } from '../../components/pillStatus';
import './index.css';
import { setDeliveryOption, setOrderTypeRegular } from '../../store/actions';
import { getConfig } from '../../appConfig';
import Alert from '../../components/alert';

const { delivery } = getConfig();
class HistoryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancel: true,
      orderId: null,
      restaurantId: null,
      orderArr: props.location.state ? props.location.state.order : null,
	  showWaitToCancelOrderModal: false
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const orderArr = location?.state?.order;
    const now = moment();
    let cutoffTime = orderArr ? orderArr.cutoff_time : now;
    let cutoffTimeMoment = new moment(cutoffTime, 'YYYY-MM-DD HH:mm:ss');
    const utcOffset = cutoffTimeMoment.utcOffset();
    cutoffTimeMoment = cutoffTimeMoment.add('minutes', utcOffset);
    if (now.isBefore(cutoffTimeMoment)) {
      if (orderArr.status !== 'REFUNDED') {
        this.setState({
          isCancel: true,
          orderId: orderArr.id,
          restaurantId: orderArr.restaurant_id,
          orderArr: orderArr,
        });
      }
    }
  }

  backHandler = () => {
    this.setState({ orderId: null, isCancel: false, restaurantId: null });
    // forwardTo('/dashboard');
    forwardTo('/history');
  };

  cancelOrder = () => {
    const { orderId, isCancel, restaurantId } = this.state;
    const { location } = this.props;
    let order_id = orderId ? orderId : location?.state?.order.id;
    let restaurant_id = restaurantId ? restaurantId : location?.state?.order.restaurant_id;
    if (isCancel) {
      this.props.dispatch(checkCancelOrder(order_id, restaurant_id));
      this.setState({ orderId: null, restaurantId: null });
    }
  };

  orderAgain = () => {
    Basket.reset();
    let orderLabel = Basket.getOrderType(this.state.orderArr);
    let option = (delivery || []).find((d) => d.id === orderLabel);
    this.props.dispatch(setDeliveryOption(option));
    this.props.setOrderTypeRegular();
    forwardTo(option.route, { selectedRestaurant: this.state.orderArr });
  };

  handleCancelOrderModal = (flag, order) => {
    if(checkIfOrderCanBeCancelled(order)){
		this.props.dispatch(setOrdersProp('cancelOrderModal', flag));
	}else{
		this.setState({showWaitToCancelOrderModal: true});
	}
  };

  handleInfoCancelOrderModal = (flag) => {
    this.props.dispatch(setOrdersProp('infoCancelOrderModal', flag));
  };

  formatPaymentString = (str) => {
    let splitedStr = str.split(' ').splice(2).join(' ');
    return splitedStr;
  };

  // drawOrderSummary = (__, order, cards, orderType, payment_token, basketInstance) => {
  //   return (
  //     <>
  //       <Box>
  //         <BoxHeader>
  //           <p className="light-text">
  //             <SmallText tag="strong">{__('Order Location')}:</SmallText>{' '}
  //             <SmallText>{order.restaurant_name}</SmallText>
  //             <br />
  //             <SmallText tag="strong">{__('Order Time')}:</SmallText>{' '}
  //             <SmallText>{basketInstance.formatOrderTime().replace(/\//g, 'at')}</SmallText>
  //             <br />
  //             {basketInstance.getTotal() === 0 ? null : (
  //               <>
  //                 <SmallText tag="strong">{__('Payment Method')} : </SmallText>
  //                 {payment_token === 'apple' || payment_token === 'google' ? (
  //                   <>
  //                     <SmallText>{basketInstance.formatPaymentMethod(cards, __)}</SmallText>
  //                   </>
  //                 ) : (
  //                   <SmallText>
  //                     {this.formatPaymentString(basketInstance.formatPaymentMethod(cards, __))}
  //                   </SmallText>
  //                 )}
  //               </>
  //             )}
  //             <br />
  //             {order && order.status === 'REFUNDED' ? (
  //               <>
  //                 <SmallText tag="strong">{__('Order Status')}:</SmallText>{' '}
  //                 <SmallText>{'Refunded'}</SmallText>
  //               </>
  //             ) : (
  //               ''
  //             )}
  //           </p>
  //         </BoxHeader>
  //         <OrderContent basketInstance={basketInstance} type="orderHistory" />
  //         <AmountPaid order={order} cards={cards} />
  //       </Box>
  //       <div>
  //         <IonButton
  //           color="primary"
  //           fill="solid"
  //           shape="round"
  //           onClick={() => this.orderAgain()}
  //           className="reorder-btn"
  //         >
  //           {__('Order it again')}
  //         </IonButton>
  //       </div>

  //       <IonButton
  //         fill="clear"
  //         className="link underlined cancel-btn"
  //         color="dark"
  //         size="default"
  //         onClick={() => this.handleCancelOrderModal(true)}
  //       >
  //         {__('Cancel this order')}
  //       </IonButton>
  //     </>
  //   );
  // };
  drawContent = (__, order, orderType, basketInstance, status) => {
    if (!order?.is_gift) {
      if (orderType === 'Click & Collect') {
        return (
          <>
            <div className="box-wrapper order-content-details">
              <div>
                <StrongText>{__('Collect from')}</StrongText>
                <StrongText className="block">{order.restaurant_name}</StrongText>
                <NormalText className="block">
                  {__('Collect at')}:{' '}
                  {basketInstance.formatOrderTime(true, 'DD MMM YYYY [at] h:mm a')}
                </NormalText>
                <NormalText className="block">
                  {__('Order placed on')}:{' '}
                  {moment(order.created_at).format('DD MMM YYYY [at] h:mm a')}
                </NormalText>
              </div>
            </div>
          </>
        );
      } else if (orderType === 'Delivery') {
        return (
          <>
            <div className="box-wrapper order-content-details">
              <div>
                <StrongText>{__('Delivery from')}</StrongText>
                <StrongText className="block">{order.restaurant_name}</StrongText>
                <NormalText className="block">
                  {__('Order placed on')}:{' '}
                  {moment(order.created_at).format('D MMM YYYY [at] h:mm a')}
                </NormalText>
                <NormalText className="block">
                  {__('Delivery at')}: {basketInstance.formatOrderTime(true)}
                </NormalText>
              </div>
            </div>
          </>
        );
      } else if (orderType === 'Outpost Drop-Off') {
        return (
          <>
            <div className="box-wrapper order-content-details">
              <div>
                <StrongText>{__('Drop-off at')}</StrongText>
                <StrongText className="block">{order.restaurant_name}</StrongText>
                <NormalText className="block">
                  {__('Order placed on')}:{' '}
                  {moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}
                </NormalText>
                <NormalText className="block">
                  {__('Drop-off Time')}: {basketInstance.formatOrderTime(true)}
                </NormalText>
              </div>
            </div>
          </>
        );
      } else if (orderType === 'Table') {
        return (
          <>
            <div className="box-wrapper order-content-details">
              <div>
                <StrongText>{__('Ordered at')}</StrongText>
                <StrongText className="block">{order.restaurant_name}</StrongText>
                <StrongText className="block">
                  {__('Table Number')}-{order.table_name}
                </StrongText>
                <NormalText className="block">
                  {__('Order placed on')}:{' '}
                  {moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}
                </NormalText>
              </div>
            </div>
          </>
        );
      }
    }
    if (orderType === 'scheduled-collection') {
      return (
        <>
          <div className="box-wrapper order-content-details">
            <div>
              <StrongText>{__('Collect from')}</StrongText>
              <StrongText className="block">{order.restaurant_name}</StrongText>
              <NormalText className="block">
                {__('Collect at')}:{' '}
                {basketInstance.formatOrderTime(true, 'DD MMM YYYY [at] h:mm a')}
              </NormalText>
              <NormalText className="block">
                {__('Order placed on')}:{' '}
                {moment(order.created_at).format('DD MMM YYYY [at] h:mm a')}
              </NormalText>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  render() {
    const { __, location, cards, latestOrderEvent } = this.props;
    const order = location.state.order;
    const basketInstance = createNewBasketInstance();
    basketInstance.recreateOrder(order);
    const orderType = basketInstance.getOrderType();
    const payment_token =
      basketInstance &&
      basketInstance.orderFromHistory &&
      basketInstance.orderFromHistory.payment_token;

    updateOrder(order, latestOrderEvent);
    const pillItems = lastEventForPill(order, basketInstance.getOrderType(order), __);

    return (
      <Loading>
        <Layout
          hideSecondToolbar
          headerWithTitle
          headerTitle={`${__('Order')} #${order.id}`}
          backHandler={this.backHandler}
          scrollY={false}
        >
          <div className="absolute-content history-details scrollable-y">
            <Title className="web-only">{`${__('Order')} #${order.id}`}</Title>
            <PillStatusGroup className="uppercase" items={pillItems} />
            <Spacer size={1} />

            {this.drawContent(__, order, orderType, basketInstance)}
            <Spacer size={1} />
            <StrongText>{__('Items Ordered')}</StrongText>
            <OrderContent basketInstance={basketInstance} type="orderHistory" />
            <Spacer size={1} />
            <StrongText>{__('Payment')}</StrongText>
            <AmountPaid order={order} cards={cards} />
            <Spacer size={1} />
            <StrongText>{__('Order Status')}</StrongText>

            <OrderStatus order={order} orderType={basketInstance.getOrderType(order)} />
            {order.status.toLowerCase() == 'new' ||
            order.status.toLowerCase() == 'failed' ||
            order.status.toLowerCase() == 'cancelled' ||
            order.status.toLowerCase() == 'order_canceled' ||
            order.status.toLowerCase() == 'payment failed' ? null : (
              <IonButton
                expand="block"
                fill="clear"
                className="link underlined cancel-btn"
                color="dark"
                onClick={() => this.handleCancelOrderModal(true, order)}
              >
                {__('Cancel this order')}
              </IonButton>
            )}
          </div>
        </Layout>
        <IonAlert
          isOpen={this.props.cancelOrderModal === true}
          onDidDismiss={() => this.handleCancelOrderModal(false, order)}
          header={__('Confirm')}
          message={__('Do you want to cancel this order?')}
          // message={__(
          //   'Sorry, it is not currently possible to cancel this order. Please refer to <a href="/faq" >FAQ</a> for more help.',
          // )}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: __('Remove'),
              handler: () => this.cancelOrder(),
            },
          ]}
        />
        <IonAlert
          cssClass="custom-ion-alert"
          isOpen={this.props.infoCancelOrderModal === true}
          onDidDismiss={() => this.handleInfoCancelOrderModal(false)}
          header={__('Cancel Order')}
          message={__(
            'Sorry, it is not currently possible to cancel this order. Please refer to <a href="/faq" >FAQ</a> for more help.',
          )}
          buttons={[
            {
              text: __('OK'),
              role: 'cancel',
              cssClass: 'secondary',
            },
          ]}
        />
		<IonAlert
          isOpen={this.state.showWaitToCancelOrderModal === true}
          onDidDismiss={() => this.setState({showWaitToCancelOrderModal: false})}
          header={__(getConfig()?.cancelOrderConfig?.header || "" )}
          message={__(getConfig()?.cancelOrderConfig?.message || "Kindly wait for 5 minutes before canceling your order.")}
          buttons={[
            {
              text: __('OK'),
              role: 'cancel',
            },
          ]}
        />
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const orders = store.orders;
  const { restaurants } = store.restaurants;
  const { cancelOrderModal, latestOrderEvent, infoCancelOrderModal } = orders;
  let lastOrder = null;
  const orderHistory = orders.orderHistory;
  if (orderHistory && orderHistory.length > 0) {
    lastOrder = orderHistory[0];
  }
  return {
    lastOrder,
    cards: orders.cards || [],
    restaurants: restaurants || [],
    cancelOrderModal,
    latestOrderEvent,
    infoCancelOrderModal,
  };
};

const dispatchToProps = { setOrderTypeRegular };

export default connect(mapStateToProps, dispatchToProps)(withTranslation(HistoryDetails));
