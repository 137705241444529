import { Capacitor } from '@capacitor/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonList,
  IonCheckbox,
  IonNote,
  IonAlert,
  IonToggle,
} from '@ionic/react';
import { getConfig } from '../../appConfig';
import Layout from '../../components/layout';
import { NormalText, Spacer, FieldError, Title } from '../../components/common';
import { validateForm, isDefined, forwardTo, goBack, isWebConfig, isNativeAndroid } from '../../lib/utils';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import { updateProfile, setModal, loading, deleteUser } from '../../store/actions';
import {
  beforeShowTimePicker,
  beforeCloseTimePicker,
  setOrderTypeRegular,
} from '../../store/actions';
import Loading from '../../components/spinner';
import './index.css';
import defaultImg from '../../assets/images/gray-avatar.png';
import Mobiscroll from '../../components/mobiscroll';

const { configS3 } = getConfig();
const { DatePicker, SelectOption } = Mobiscroll;
const isWeb = () => Capacitor.getPlatform() === 'web';
class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: this.props.profile.first_name || '',
      last_name: this.props.profile.last_name || '',
      email: this.props.profile.email || '',
      mobile: this.props.profile.mobile || '',
      birthday: this.props.profile.birthday ? this.props.profile.birthday : '',
      profile_image_url: this.props.profile.profile_image_url || defaultImg,
      imageFile: null,
      is_subscribed: this.props.profile.is_subscribed || false,
      formErrors: {},
      deleteAvatarImageAlert: false,
      locale: this.props.profile.locale || getConfig().localization.defaultLocale,
      is_corporate: this.props.profile.is_corporate || false,
      company_name: this.props.profile.company_name || '',
      company_address: this.props.profile.company_address || '',
      company_county: this.props.profile.company_county || '',
      company_town: this.props.profile.company_town || '',
      company_postcode: this.props.profile.company_postcode || '',
      company_billing_email: this.props.profile.company_billing_email || '',
      mobile_code: this.props.profile.mobile_code ? this.props.profile.mobile_code : null,
      mobile_value: this.props.profile.mobile_value
        ? this.props.profile.mobile_value
        : this.props.profile.mobile == this.props.profile.mobile_code
        ? ''
        : this.props.profile.mobile,
              "hasDeleteAccount":true,
      closeMyAccount:false
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.formConfig = {
      email: { type: 'email', required: false },
      mobile: { type: 'tel', required: false },
      locale: { type: 'text', required: false },
      company_billing_email: { type: 'email', required: this.props.profile.is_corporate },
    };
    // this.triggerInputFile = this.triggerInputFile.bind(this);
    this.inputRef = React.createRef();
    this.onChangeFile = this.onChangeFile.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.first_name !== prevProps.profile.first_name) {
      this.setState({ first_name: this.props.profile.first_name });
    }
    if (this.props.profile.last_name !== prevProps.profile.last_name) {
      this.setState({ last_name: this.props.profile.last_name });
    }
    if (this.props.profile.email !== prevProps.profile.email) {
      this.setState({ email: this.props.profile.email });
    }
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({
        mobile: this.props.profile.mobile,
        mobile_code: this.props.profile.mobile_code ? this.props.profile.mobile_code : null,
        mobile_value: this.props.profile.mobile_value
          ? this.props.profile.mobile_value
          : this.props.profile.mobile == this.props.profile.mobile_code
          ? ''
          : this.props.profile.mobile,
      });
    }
    if (this.props.profile.is_corporate !== prevProps.profile.is_corporate) {
      this.setState({ is_corporate: this.props.profile.is_corporate });
    }
    if (this.props.profile.company_name !== prevProps.profile.company_name) {
      this.setState({ company_name: this.props.profile.company_name });
    }
    if (this.props.profile.company_address !== prevProps.profile.company_address) {
      this.setState({ company_address: this.props.profile.company_address });
    }
    if (this.props.profile.company_town !== prevProps.profile.company_town) {
      this.setState({ company_town: this.props.profile.company_town });
    }
    if (this.props.profile.company_county !== prevProps.profile.company_county) {
      this.setState({ company_county: this.props.profile.company_county });
    }
    if (this.props.profile.company_postcode !== prevProps.profile.company_postcode) {
      this.setState({ company_postcode: this.props.profile.company_postcode });
    }
    if (this.props.profile.company_billing_email !== prevProps.profile.company_billing_email) {
      this.setState({ company_billing_email: this.props.profile.company_billing_email });
    }
    if (this.props.profile.birthday !== prevProps.profile.birthday) {
      this.setState({ birthday: this.props.profile.birthday ? this.props.profile.birthday : '' });
    }
    if (this.props.profile.locale !== prevProps.profile.locale) {
      this.setState({
        locale: this.props.profile.locale || getConfig().localization.defaultLocale,
      });
    }
    if (this.props.profile.profile_image_url !== prevProps.profile.profile_image_url) {
      if (this.props.profile.profile_image_url) {
        this.setState({ profile_image_url: this.props.profile.profile_image_url });
      } else {
        this.setState({ profile_image_url: defaultImg });
      }
    }
    if (this.props.profile.is_subscribed !== prevProps.profile.is_subscribed) {
      this.setState({ is_subscribed: this.props.profile.is_subscribed });
    }
  }

  handleInput = (key, val) => {
    if (key == 'mobile_value') {
      this.setState({ mobile_value: val, mobile: `${this.state.mobile_code}${val}` });
    } else {
      this.setState({ [key]: val });
    }
    this.props.dispatch(beforeCloseTimePicker());
  };

  handleLanguageInput = (event, data) => {
    this.setState({ locale: data.getVal() });
  };
  /* 
  async takePicture() {
    if (Capacitor.getPlatform() !== 'web') {
      await Camera.getPhoto({
        quality: 50,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Prompt,
      }).then((imageData) => {
        this.setState({
          imageFile: imageData.dataUrl,
          extension: imageData.format,
          profile_image_url: imageData.dataUrl,
        });
      });
    }
  }
 */
  getMimeType = (extension) => {
    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'gif':
        return 'image/gif';
      default:
        return undefined;
    }
  };

  handleSave() {
    let formErrors = validateForm(this.formConfig, this.state);
    this.setState({ formErrors });

    if (Object.keys(formErrors).length === 0) {
      const {
        first_name,
        last_name,
        email,
        mobile,
        birthday,
        profile_image_url,
        imageFile,
        is_subscribed,
        locale,
        is_corporate,
        company_name,
        company_address,
        company_town,
        company_billing_email,
        company_county,
        company_postcode,
        mobile_code,
        mobile_value,
      } = this.state;
      const profile = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        mobile: mobile,
        birthday: birthday,
        profile_image_url: !this.props.profile.profile_image_url ? null : profile_image_url,
        is_subscribed,
        locale,
        is_corporate,
        company_name,
        company_address,
        company_town,
        company_billing_email,
        company_county,
        company_postcode,
        mobile_code,
        mobile_value,
      };
      if (!isDefined(imageFile)) {
        this.props.dispatch(updateProfile(profile));
      } else {
        /* const imageName = configS3.imageNamePrefix + this.props.profile.id; //+ '.' + this.state.extension
        this.props.dispatch(loading(true));
        fetch(this.state.profile_image_url)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new window.File([blob], imageName, {
              type: this.getMimeType(this.state.extension),
            });
            S3Client.uploadFile(file, imageName)
              .then((data) => {
                profile.profile_image_url = data.location;
                this.props.dispatch(updateProfile(profile));
                this.setState({ imageFile: null });
                this.props.dispatch(loading(false));
              })
              .catch(() => {
                this.props.dispatch(loading(false));
              });
          }); */
      }
    }
  }

  removeProfileImage = () => {
    const { profile } = this.props;
    const profile_image_url = profile.profile_image_url;
    this.handleAvatarDeleteAlert(false);
    if (profile_image_url) {
      let imageName = profile_image_url.split('/');
      if (imageName.length > 0) {
        imageName = imageName[imageName.length - 1];
        this.props.dispatch(updateProfile({ profile_image_url: null }));
        this.setState({ imageFile: null });
      }
    }
  };
  /* 
  triggerInputFile() {
    if (Capacitor.getPlatform() === 'web') {
      if (this.inputRef) {
        this.inputRef.current.click();
      }
    } else {
      this.takePicture();
    }
  }
 */
  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();
    const imageFile = event.target.files[0];
    let reader = new window.FileReader();
    reader.readAsDataURL(imageFile);
    let extension = imageFile.name.split('.').pop();
    reader.onloadend = () => {
      this.setState({
        imageFile,
        profile_image_url: reader.result,
        extension,
      });
    };
  }

  handleAvatarDeleteAlert = (flag = true) => this.setState({ deleteAvatarImageAlert: flag });

  handlePeriodicalSaga = (flag) => {
    const { dispatch } = this.props;
    dispatch({ type: 'SET_COMMON_PROP', key: 'emitterFlag', value: flag });
  };

  formatDataForSelect = (langs) => {
    let arrForSelect = [];
    Object.keys(langs).forEach((key) => {
      arrForSelect.push({ text: langs[key], value: key });
    });
    return [{ text: '', value: null }, ...arrForSelect];
  };

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.addCardFromAccount
    ) {
      forwardTo('/dashboard');
    } else {
      goBack();
    }
  };

  handleCardsClick = () => {
    const { history, setOrderTypeRegular } = this.props;
    setOrderTypeRegular();
    history.push('/cards', { addCardFromAccount: true });
  };
  setMobileCode = (val) => {
    this.setState({ mobile_code: val, mobile: `${val}${this.state.mobile_value}` });
  };
  render() {
    const {
      __,
      isProfileModalOpen,
      history,
      profile,
      isShowTimePicker,
      hasMembership,
    } = this.props;
    const {
      first_name,
      last_name,
      email,
      mobile,
      birthday,
      formErrors,
      profile_image_url,
      is_subscribed,
      deleteAvatarImageAlert,
      locale,
      is_corporate,
      company_name,
      company_address,
      company_town,
      company_billing_email,
      company_county,
      company_postcode,
      mobile_code,
      mobile_value,
      closeMyAccount
    } = this.state;
    // const dateFormat = moment()._locale._longDateFormat.L
    const languages = Object.keys(getConfig().localization.supportedLocales);
    const newClass = isShowTimePicker ? 'red' : 'gray';
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    const defaultDateValue = moment().subtract(18, 'years');
    return (
      <Loading>
        <Layout
          showHamburgerIcon
          hideSecondToolbar
          headerWithTitle
          headerTitle="Your Account"
          backHandler={this.backHandler}
          scrollY={isWebConfig() ? false : true}
        >
          <div className="absolute-content scrollable-y">
            <Title className="web-only">{__('Your Account')}</Title>
            {isWebConfig() && <Spacer size={1} />}
            <IonList>
              <div className="input-field-container">
                <NormalText>{__('First Name')}</NormalText>
                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    onIonInput={(e) => this.handleInput('first_name', e.target.value)}
                    clearInput
                    required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={first_name}
                  ></IonInput>
                </IonItem>
              </div>

              <div className="input-field-container">
                <NormalText>{__('Last Name')}</NormalText>
                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    onIonInput={(e) => this.handleInput('last_name', e.target.value)}
                    clearInput
                    required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={last_name}
                  ></IonInput>
                </IonItem>
              </div>

              <div className="input-field-container">
                <NormalText>{__('Email')}</NormalText>
                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    onIonInput={(e) => this.handleInput('email', e.target.value)}
                    clearInput
                    required={true}
                    type="email"
                    pattern="email"
                    inputmode="email"
                    value={email}
                  ></IonInput>
                </IonItem>
              </div>
              {formErrors.email ? (
                <FieldError className="field-error" value={__(formErrors.email)} />
              ) : null}

              <div className="input-field-container">
                <NormalText>{__('Mobile Number')}</NormalText>
                <div>
                  <IonItem lines="none" className="input-field-wrapper inline-input-field-wrapper">
                    <div>
                      <SelectOption
                        onSet={(e, val) => this.setMobileCode(val.getVal())}
                        display="center"
                        value={mobile_code}
                        data={getConfig().country_code_data}
                        onInit={(e, val) => {
                          if (!mobile_code) {
                            this.setMobileCode(val._tempWheelArray[0]);
                          }
                        }}
                      />
                    </div>
                    <IonInput
                      onIonInput={(e) => this.handleInput('mobile_value', e.target.value)}
                      clearInput
                      required={false}
                      type="tel"
                      pattern="tel"
                      inputmode="tel"
                      placeholder="XXXXXXXXXXXX"
                      value={mobile_value}
                    ></IonInput>
                  </IonItem>
                </div>
              </div>
              {formErrors.mobile ? (
                <FieldError className="field-error" value={__(formErrors.mobile)} />
              ) : null}
              <div className="input-field-container">
                <NormalText>{__('Date Of Birth')}</NormalText>
                <IonItem className="input-field-wrapper" lines="none">
                  <DatePicker
                    className="data-picker-input"
                    cssClass={isNativeAndroid() ? "mobiscroll-date-picker" : ""}
                    display="bottom"
                    setText={__('Done')}
                    cancelText={__('Cancel')}
                    lang={profile.locale}
                    defaultValue={defaultDateValue}
                    max={yesterday}
                    value={birthday}
                    onSet={(e, a) => this.handleInput('birthday', a.element.value)}
                    dateFormat="dd-mm-yy"
                    onBeforeShow={() => {
                      this.props.dispatch(beforeShowTimePicker());
                    }}
                    onClose={() => {
                      this.props.dispatch(beforeCloseTimePicker());
                      this.handlePeriodicalSaga(true);
                    }}
                    onShow={() => this.handlePeriodicalSaga(false)}
                  />
                </IonItem>
              </div>

              {languages.length <= 1 ? null : (
                <div className="input-field-container">
                  <NormalText>{__('Language')}</NormalText>
                  <IonItem lines="none" className="input-field-wrapper dropdown-field">
                    <SelectOption
                      display="center"
                      onSet={(e, a) => this.handleLanguageInput(e, a)}
                      data={this.formatDataForSelect(getConfig().localization.supportedLocales)}
                      label="Location"
                      value={locale}
                      inputStyle="box"
                      placeholder={__('Select one')}
                      cancelText={__('Cancel')}
                      setText={__('OK')}
                      disabled={this.props.isShowTimePicker ? true : false}
                      // onClose={ () => { this.handlePeriodicalSaga(true) }}
                      // onShow={ () => this.handlePeriodicalSaga(false) }
                    />
                  </IonItem>
                </div>
              )}
              {is_corporate && (
                <>
                  <IonItem lines="none" disabled={true}>
                    <IonLabel>{__('Business user')}</IonLabel>
                    <IonToggle
                      style={{ paddingRight: '16px' }}
                      color="primary"
                      slot="end"
                      checked={is_corporate}
                      onIonChange={(e) => this.handleInput('is_corporate', e.detail.checked)}
                    />
                  </IonItem>
                  <div className="input-field-container">
                    <NormalText>{__('Company Name')}</NormalText>
                    <IonItem className="input-field-wrapper" lines="none">
                      <IonInput
                        onIonInput={(e) => this.handleInput('company_name', e.target.value)}
                        clearInput
                        required={true}
                        type="text"
                        pattern="text"
                        inputmode="text"
                        value={company_name}
                      ></IonInput>
                    </IonItem>
                  </div>

                  <div className="input-field-container">
                    <NormalText>{__('Company Address')}</NormalText>
                    <IonItem className="input-field-wrapper" lines="none">
                      <IonInput
                        onIonInput={(e) => this.handleInput('company_address', e.target.value)}
                        clearInput
                        required={true}
                        type="text"
                        pattern="text"
                        inputmode="text"
                        value={company_address}
                      ></IonInput>
                    </IonItem>
                  </div>
                  <div className="input-field-container">
                    <NormalText>{__('Company Town')}</NormalText>
                    <IonItem className="input-field-wrapper" lines="none">
                      <IonInput
                        onIonInput={(e) => this.handleInput('company_town', e.target.value)}
                        clearInput
                        required={true}
                        type="text"
                        pattern="text"
                        inputmode="text"
                        value={company_town}
                      ></IonInput>
                    </IonItem>
                  </div>
                  <div className="input-field-container">
                    <NormalText>{__('Company County')}</NormalText>
                    <IonItem className="input-field-wrapper" lines="none">
                      <IonInput
                        onIonInput={(e) => this.handleInput('company_county', e.target.value)}
                        clearInput
                        required={true}
                        type="text"
                        pattern="text"
                        inputmode="text"
                        value={company_county}
                      ></IonInput>
                    </IonItem>
                  </div>
                  <div className="input-field-container">
                    <NormalText>{__('Company Postcode')}</NormalText>
                    <IonItem className="input-field-wrapper" lines="none">
                      <IonInput
                        onIonInput={(e) => this.handleInput('company_postcode', e.target.value)}
                        clearInput
                        required={true}
                        type="text"
                        pattern="text"
                        inputmode="text"
                        value={company_postcode}
                      ></IonInput>
                    </IonItem>
                  </div>
                  <div className="input-field-container">
                    <NormalText>{__('Company Billing Email')}</NormalText>
                    <IonItem className="input-field-wrapper" lines="none">
                      <IonInput
                        onIonInput={(e) =>
                          this.handleInput('company_billing_email', e.target.value)
                        }
                        clearInput
                        required={true}
                        type="text"
                        pattern="text"
                        inputmode="text"
                        value={company_billing_email}
                      ></IonInput>
                    </IonItem>
                  </div>
                  {formErrors.company_billing_email ? (
                    <FieldError
                      className="field-error"
                      value={__(formErrors.company_billing_email)}
                    />
                  ) : null}
                </>
              )}
              <Spacer />
              <NormalText>{__('Communication Preferences')}</NormalText>
              <IonItem lines="none">
                <div tabIndex="-1"></div>
                <IonCheckbox
                  color="secondary"
                  slot="start"
                  checked={is_subscribed}
                  onIonChange={(e) => this.handleInput('is_subscribed', e.detail.checked)}
                />
                <IonLabel className="ion-text-wrap">
                  <IonNote>
                    {__('I would like to receive') + ' ' + __('updates and offers via email')}
                  </IonNote>
                </IonLabel>
              </IonItem>
            </IonList>

            <IonButton expand="block" color="primary" onClick={() => this.handleSave()}>
              {__('Save')}
            </IonButton>
            { isWeb() ? (
              <IonButton color="black" expand="block" fill="outline" onClick={this.handleCardsClick}>
                {__('Manage My Payment Cards')}
              </IonButton>
            ) : null }
            {hasMembership && (
              <IonButton
                color="black"
                expand="block"
                fill="outline"
                onClick={() => history.push('/my-membership', { addCardFromAccount: true })}
              >
                {__('Manage My Membership')}
              </IonButton>
            )}
            {getConfig().flags.hasDeleteAccount && (
							<IonButton 
                expand="block" 
                fill="clear" 
                color="danger" 
                className="underlined" 
                onClick={() => this.setState({ closeMyAccount: true })}
              >
								{__('Close my account')}
							</IonButton>
						)}
          </div>
        </Layout>
        <IonAlert
					isOpen={closeMyAccount}
					onDidDismiss={() => this.setState({ closeMyAccount: false })}
					header={__('Close Your Account')}
					message={__('Are you sure you wish to close your account? Any points and vouchers you have earned will be permanently lost')}
					buttons={[
						{
							text: __('Cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.setState({ closeMyAccount: false }),
						},
						{
							text: __('OK'),
							handler: () => {
                this.props.dispatch(deleteUser(profile.user_token))
              },
						},
					]}
				/>
        <IonAlert
          isOpen={isProfileModalOpen}
          onDidDismiss={() => this.props.dispatch(setModal('isProfileModalOpen', false))}
          header={__('Success')}
          message={__('Profile is updated.')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.props.dispatch(setModal('isProfileModalOpen', false)),
            },
          ]}
        />
        <IonAlert
          isOpen={deleteAvatarImageAlert}
          onDidDismiss={() => this.handleAvatarDeleteAlert(false)}
          header={__('Remove')}
          message={__('Do you want to remove profile image.')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.handleAvatarDeleteAlert(false),
            },
            {
              text: __('Remove'),
              handler: () => this.removeProfileImage(),
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, profile, isProfileModalOpen } = state.profile;
  const { isShowTimePicker } = state.restaurants;
  const { active_subscription } = profile;
  return {
    auth,
    profile,
    isProfileModalOpen,
    isShowTimePicker,
    hasMembership: active_subscription && active_subscription.id,
  };
};

const dispatchToProps = {
  setOrderTypeRegular,
};

export default connect(stateToProps, dispatchToProps)(withTranslation(Account));
