import React from 'react';
import {
  IonButton,
  IonItem,
  IonInput /*, isPlatform */,
  IonModal,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
} from '@ionic/react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import Clickup from '../../components/clickup';
import {
  Title,
  Spacer,
  NormalText,
  SmallText,
  FieldError,
  Subtitle,
  StrongText,
} from '../../components/common';
import { validateForm, isDefined, isWebConfig } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { sendVoucherCode } from '../../store/actions';
import { forwardTo } from '../../lib/utils';
import QRCodeCanvas from 'qrcode.react';
import './index.css';
import NoDataSecond from '../../components/noDataSecond';
import Header from '../../components/header';
import Modal from '../../components/modal';

const VoucherItem = ({ reward, id, __, action }) => {
  return (
    <div key={id} onClick={() => action(id)} className={'voucher-content'}>
      <img alt="" src={reward.image} width="100%" />
      <div>
        <NormalText>{__(reward.small_print)}</NormalText>
      </div>
    </div>
  );
};

class VouchersRaw extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherCode: '',
      selectedVoucher: null,
      formErrors: {
        voucherCode: '',
      },
      redeemVoucherCode:
        (this.props.location &&
          this.props.location.state &&
          this.props.location.state.redeemVoucherCode) ||
        false,
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    if (
      isDefined(this.props.location) &&
      isDefined(this.props.location.state) &&
      isDefined(this.props.location.state.openVoucherModal)
    ) {
      setTimeout(() => {
        this.viewVoucher(this.props.location.state.openVoucherModal);
      }, 500);
    }
  }

  viewVoucher = (id) => {
    const { vouchers } = this.props;
    this.setState({ selectedVoucher: vouchers.find((v) => v.id === id) });
  };

  redeemVoucher = () => {
    const { voucherCode } = this.state;
    if (voucherCode !== '') {
    }
  };

  clearFields = () => {
    this.setState({ voucherCode: '' });
  };

  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      dispatch(sendVoucherCode(data, false));
    }
  };

  handlerOfVoucherCode = (e) => {
    const val = e.target.value.toUpperCase();
    this.setState({ voucherCode: val });
  };

  splitedExpiryDate = (expDate) => expDate.split('-').reverse().join('/');

  componentDidUpdate(prevProps) {
    if (this.props.isVoucherType !== prevProps.isVoucherType) {
      if (this.props.isVoucherType) {
        this.setState({ redeemVoucherCode: true });
      } else {
        this.setState({ redeemVoucherCode: false });
      }
    }
  }

  render() {
    const { __, vouchers, qr_code, isVoucherType } = this.props;
    const { voucherCode, selectedVoucher, redeemVoucherCode } = this.state;

    const clickupContent = (
      <Clickup label={__('Redeem Offer Code')} height="320">
        <div className="absolute-wrapper flex-row-wrapper">
          <div className="padded no-padding-bottom centered scrollable-y">
            <p className="no-margin-top">
              {__('Enter a valid voucher code below to receive your reward')}
            </p>
            <Spacer size={1} />
            <div className="input-field-container">
              <NormalText>{__('Offer Code')}</NormalText>

              <IonItem lines="none" className="input-field-wrapper">
                <IonInput value={voucherCode} onIonInput={(e) => this.handlerOfVoucherCode(e)} />
              </IonItem>
            </div>
            <FieldError className="field-error" value={__(this.state.formErrors.voucherCode)} />
          </div>
          <div className="padded no-padding-top flex-min">
            <IonButton
              disabled={voucherCode === ''}
              color="secondary"
              expand="block"
              onClick={this.sendCode}
            >
              {__('Submit')}
            </IonButton>
          </div>
        </div>
      </Clickup>
    );
    return (
      <div className="absolute-content-vocuher flex-row-wrapper">
        {redeemVoucherCode || isVoucherType ? (
          <div className="absolute-content flex-row-wrapper">
            <div className="flex-min ion-text-left">
              <Title>{__('Redeem Voucher Code')}</Title>
              <Spacer />
            </div>
            <div className="no-padding-bottom voucher-code">
              <IonItem style={{ '--min-height': '32px' }}>
                <div className="voucher-code-field">
                  <StrongText>{__(isWebConfig() ? 'Code' : 'Voucher Code')}</StrongText>
                  <IonInput
                    className="mobile-field"
                    onIonInput={(e) => this.handlerOfVoucherCode(e)}
                    clearInput
                    required={true}
                    value={voucherCode}
                    placeholder={__('Enter code')}
                  />
                </div>
              </IonItem>
            </div>
            <div className="padded no-padding-top flex-min">
              <IonButton disabled={voucherCode === ''} expand="block" onClick={this.sendCode}>
                {__('Apply Code')}
              </IonButton>
            </div>
          </div>
        ) : (
          <>
            <div className="flex-min ion-text-center">
              <Title className="web-only">{__('Voucher Wallet')}</Title>
              <Spacer size={1} />
            </div>
            <div className="scrollable-y">
              {!vouchers || vouchers.length === 0 ? (
                <NoDataSecond label="Your vouchers will appear here" />
              ) : null}
              {vouchers &&
                vouchers.map((voucher, index) => {
                  return (
                    <VoucherItem
                      key={'vc-' + index}
                      {...voucher}
                      __={__}
                      action={this.viewVoucher}
                    />
                  );
                })}
              <Spacer size={2} />
            </div>
            <div className="flex-min">{clickupContent}</div>
          </>
        )}
        {isDefined(selectedVoucher) ? (
          <Modal
            isOpen={selectedVoucher}
            backdrop-dismiss="true"
            onDidDismiss={() => this.setState({ selectedVoucher: null })}
          >
            <div className="voucher-info-wrapper">
              <div className="voucher-intro-modal-content">
                {isDefined(!selectedVoucher.reward.image) ? (
                  <div
                    className="voucher-info-image"
                    style={{ backgroundImage: 'url(' + selectedVoucher.reward.image + ')' }}
                  ></div>
                ) : (
                  <div
                    className="voucher-info-image"
                    style={{
                      backgroundImage: 'url(' + require('../../assets/images/no-photo.png') + ')',
                    }}
                  ></div>
                )}
                <div className="modal-text">
                  <Title className="ion-text-center uppercase voucher-info-title">
                    {selectedVoucher.reward.name}
                  </Title>
                  <NormalText className="ion-text-center no-margin-bottom" tag="p" color="primary">
                    {__(selectedVoucher.reward.small_print)}
                  </NormalText>
                  {/*
                    <NormalText className="ion-text-center no-margin-top" tag="p" color="primary">
                      {__('Expires')}: {this.splitedExpiryDate(selectedVoucher.reward.expiry_date)}
                    </NormalText>
                  */}
                  <Subtitle
                    className="ion-text-center no-margin-bottom"
                    color="primary"
                    onClick={() => {
                      forwardTo('/terms');
                    }}
                  >
                    {__('Terms & Conditions')}
                  </Subtitle>
                  <NormalText className="ion-text-center" tag="p" color="primary">
                    {__(selectedVoucher.reward.description)}
                  </NormalText>
                </div>
                <Spacer />
                {isDefined(qr_code) ? (
                  <div className="qr-holder centered">
                    <QRCodeCanvas value={qr_code} size={200} />
                  </div>
                ) : (
                  <div className="noQrCode">
                    <h5>{__('NO QR CODE')}</h5>
                  </div>
                )}
                <Spacer />
              </div>
              <div className="flex-min">
                <IonButton
                  expand="block"
                  color="primary"
                  className="default-button modal-button"
                  onClick={() => this.setState({ selectedVoucher: null })}
                >
                  {__('Close')}
                </IonButton>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

const stateToProps = (state) => {
  return {
    vouchers: state.profile.vouchers || [],
    qr_code: state.profile.profile.qr_code,
  };
};

export const Vouchers = connect(stateToProps)(withTranslation(VouchersRaw));

const VouchersWrapped = (props) => {
  return (
    <Loading>
      <Layout
        showHamburgerIcon
        hideSecondToolbar
        headerWithTitle
        headerTitle={'Voucher Wallet'}
        scrollY={false}
      >
        <Vouchers {...props} />
      </Layout>
    </Loading>
  );
};

export default withTranslation(VouchersWrapped);
